import BlogPage from "./BlogPage";
import CmsEntity from "./CmsEntity";
import UploadFile from "./UploadFile";
import CmsMixedItem from "./CmsMixedItem";

export default class HomePage extends CmsEntity {
    public hero_text = "";
    public site_title = "";
    public top_content = "";
    public hero_sub_text = "";
    public bottom_content = "";
    public site_description = "";
    public hero_image = new UploadFile();
    public banner_image = new UploadFile();

    public featured_posts = new Array<BlogPage>();
    public featured_grid = new Array<CmsMixedItem>();
    
    constructor(data:any = {}) {
        super(data);
        if (!data)
            return;

        this.hero_text = data.hero_text;
        this.site_title = data.site_title;
        this.top_content = data.top_content;
        this.hero_sub_text = data.hero_sub_text;
        this.bottom_content = data.bottom_content;
        this.site_description = data.site_description;
        this.hero_image = new UploadFile(data.hero_image);
        this.banner_image = new UploadFile(data.banner_image);

        if (data.featured_posts)
            this.featured_posts = data.featured_posts.map((p:any) => new BlogPage(p));
        if (data.featured_grid)
            this.featured_grid = data.featured_grid.map((p:any) => new CmsMixedItem(p));
    }
}