import { Link, useHistory } from "react-router-dom";
import React, { RefObject, useEffect, useRef, useState } from 'react';

import { User } from '../../models';
import Loader from '../form/loader';
import { useCart } from '../../stores/cartStore';
import UserStorage from '../../utils/userStorage';
import { useFlash } from '../../stores/flashStore';
import UserService from '../../services/UserService';
import { useDialog } from '../../stores/dialogStore';
import { useSidebar } from '../../stores/sidebarStore';
import { useContent } from '../../stores/siteContentStore';
import SiteContentService from '../../services/SiteContentService';
import { ErrorMessage } from '../../services/ErrorHandlingService';
import ProductSearchAutocomplete from "../product/search-autocomplete";
import CmsLink, { CmsLinkType } from '../../models/SiteContent/CmsLink';
import UserPicker, { UserPickerRefTypes } from '../../components/form/user-picker';

import logoImage from '../../assets/images/logo.png';
import userIcon from '../../assets/images/icons/user.svg';
import './styles.scss';

interface PropTypes {
    hideSearchBar?: boolean;
    hideCartButton?: boolean;
}

export default function Header(props: PropTypes) {
    const flash = useFlash();
    const dialog = useDialog();
    const cartState = useCart();
    const cart = cartState.get();
    const history = useHistory();
    const contentState = useContent();
    const sidebarState = useSidebar();
    const content = contentState.get();
    const currentUser = UserStorage.get();
    const isGhostProfile = UserStorage.hasGhostToken();
    const adminRef: RefObject<HTMLDivElement> = useRef(null);
    const cartSidebarState = sidebarState.getStateFor("cart");
    const catceptSidebarState = sidebarState.getStateFor("catcept");
    const userPickerRef: RefObject<UserPickerRefTypes> = useRef(null);
    const [isAdmin] = useState(UserStorage.isMaster());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(UserStorage.hasToken());
    const [showAdminDropdown, setShowAdminDropdown] = useState(false);
    const [logginInAsCustomer, setLogginInAsCustomer] = useState(false);

    useEffect(() => {
        if (content)
            return;

        SiteContentService.getGeneralContent();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (event: any) => {
        if (adminRef.current &&
            !adminRef.current.contains(event.target) &&
            showAdminDropdown
        ) {
            setShowAdminDropdown(false);
            userPickerRef.current?.clear();
        }
    };

    const confirmAdminLogin = (selectedUser: User) => {
        if (!selectedUser) {
            setShowAdminDropdown(false);
            userPickerRef.current?.clear();
            return;
        }

        var name = selectedUser.getAttributeValue("firstname");
        dialog.show({
            type: "confirm",
            title: "Confirm",
            message: `Log in as ${name ? name + " " : ""}(${selectedUser.email})?`
        }).then(response => {
            if (response) {
                setLogginInAsCustomer(true);
                UserService.ghostLogin(selectedUser.email)
                    .then(() => {
                        flash.show({
                            type: "success",
                            message: `Logged in as ${selectedUser.email}. You will be redirected in 2 seconds.`,
                        });
                        setTimeout(() => document.location.reload(), 2000);
                    })
                    .catch((errors: ErrorMessage[]) => {
                        flash.show({
                            type: "error",
                            message: errors.map(e => e.message).join(", "),
                        });
                        setLogginInAsCustomer(false);
                    })
                    .finally(() => setShowAdminDropdown(false));
            } else {
                setShowAdminDropdown(false);
            }

            userPickerRef.current?.clear();
        });
    };

    const onLogout = async () => {
        await UserService.logout();
        setLoggedIn(false);
        history.push("/");
    };

    const toggleCart = () => {
        if (cartSidebarState.open) {
            sidebarState.setStateFor("cart", { open: false });
            return;
        }

        (document.querySelector("html, body") as any).scrollTop = 0;

        sidebarState.setStateFor("cart", { open: true });
    };

    const toggleMenu = () => {
        var zIndex = "4";
        if (cartSidebarState.open || !mobileMenuOpen)
            zIndex = "6";

        var menuElement = document.querySelector(".categories-nav-bar");
        if (menuElement)
            (menuElement as HTMLElement).style.zIndex = zIndex;

        setMobileMenuOpen(!mobileMenuOpen);
    };

    const getHeaderLinks = (): any[] => {
        const links: any[] = [];
        content.header_links.forEach(link => {
            if (!currentUser && !link.userGroups?.includes("guest"))
                return;

            if (currentUser && !currentUser.groups.some(g => link.userGroups?.includes(g.code)))
                return;
                
            if (!link.parentTitle) {
                links.push({ link, isGroup: false });
                return;
            }

            const parent = links.find(l =>
                l.link.title === link.parentTitle ||
                l.link === link.parentTitle
            );
            if (parent) {
                parent.isGroup = true;
                if (parent.children)
                    parent.children.push(link);
                else
                    parent.children = [link];
            } else {
                const parentOnContent =
                    content.header_links.find(l => l.title === link.parentTitle);
                if (parentOnContent) {
                    links.push({
                        link: parentOnContent,
                        isGroup: true,
                        children: [link]
                    });
                } else {
                    links.push({
                        link: link.parentTitle,
                        isGroup: true,
                        children: [link]
                    });
                }
            }
        });

        return links;
    };

    const getAnchorFor = (link: CmsLink, idx: number) => {
        if (link.type === CmsLinkType.BLOG) {
            return <Link key={idx} className="link" to={"/blog/" + link.url}>
                {link.title}
            </Link>;
        } else if (link.type === CmsLinkType.PAGE) {
            return <Link key={idx} className="link" to={"/page/" + link.url}>
                {link.title}
            </Link>;
        }

        if (link.url.match(/^https?:/)) {
            return <a
                key={idx}
                className="link"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {link.title}
            </a>;
        } else {
            return <Link key={idx} className="link" to={link.url}>
                {link.title}
            </Link>;
        }
    };

    const toggleAdminDropdown = (state: boolean) => {
        setShowAdminDropdown(state);

        if (!state)
            return;

        var dd: any = document
            .querySelector(".admin-dropdown .user-picker .input-field");
        setTimeout(() => dd.focus(), 100);
    }

    const getGhostUsername = (): string => {
        var username = "";
        var firstName = currentUser?.getAttributeValue("firstname");
        var lastName = currentUser?.getAttributeValue("lastname");
        if (firstName)
            username = firstName;
        if (lastName)
            username += (username ? " " : "") + lastName;
        if (!username)
            username = currentUser?.email || "";

        return username + "'s";
    };

    return (
        <header
            id="header"
            className={window.location.pathname === "/" ? "is-home" : ""}
        >
            <div className={
                "main-bar" +
                (mobileMenuOpen ? " open" : "")
            }>
                <button
                    id="menu-mobile"
                    onClick={toggleMenu}
                    className="mobile-only"
                >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <Link
                    to="/"
                    className={"logo " + (props.hideCartButton && "fill")}
                >
                    <img src={logoImage} alt="iRemedy logo" />
                </Link>
                <div className="right-nav-bar">
                    {!props.hideSearchBar &&
                        <ProductSearchAutocomplete
                            hideFiltersButton={true}
                        />
                    }
                    {loggedIn ?
                        <Link to="/my-account" className="link">
                            {isGhostProfile ?
                                getGhostUsername()
                                :
                                "My"
                            } Account
                        </Link>
                        :
                        <Link to="/sign-in" className="link">
                            Sign In
                        </Link>
                    }
                    {!props.hideCartButton && content?.show_verified_supply_badge && 
                        <div className="verified-supply-container hint">
                            <svg className="hint-toggle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.61 44.89"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_3" data-name="Layer 3"><rect fill="#28a9e1" width="164.61" height="44.89" rx="3.39"/><line fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.5px" opacity="0.3" x1="37.39" y1="5.18" x2="37.39" y2="39.77"/><text fill="#fff" transform="translate(48.36 27.05)">Verified Supply</text><path fill="#fff" d="M24,11.57a5.65,5.65,0,0,0-3.72,1.69l-4,4c.63-.64,3-.22,3.56.34l2.41-2.4a3,3,0,0,1,1.94-.91,2.19,2.19,0,0,1,1.71.66,2.23,2.23,0,0,1,.66,1.62,2.91,2.91,0,0,1-.91,2l-4.21,4.22a2.62,2.62,0,0,1-3.66.25A1.37,1.37,0,0,0,15.85,25a5,5,0,0,0,3.56,1.44,5.61,5.61,0,0,0,4-1.66l4.25-4.25a5.66,5.66,0,0,0,1.69-4,5,5,0,0,0-5.28-5Z"/><path fill="#fff" d="M17.48,18.42a5.6,5.6,0,0,0-4,1.65L9.26,24.32a5.66,5.66,0,0,0-1.69,4,5,5,0,0,0,5.28,5,5.58,5.58,0,0,0,3.72-1.69l4-4c-.63.64-3,.23-3.56-.34l-2.41,2.41a3,3,0,0,1-1.93.9A2.22,2.22,0,0,1,11,29.92a2.27,2.27,0,0,1-.66-1.63,2.94,2.94,0,0,1,.91-2L15.45,22a2.61,2.61,0,0,1,3.65-.25,1.36,1.36,0,0,0,1.94,0,1.38,1.38,0,0,0,0-1.94A5,5,0,0,0,17.48,18.42Z"/></g></g></svg>
                            <div className="hint-content">
                                We are not an open marketplace with random suppliers 
                                but rather only distribute products from verified, 
                                licensed and authorized sources.
                            </div>
                        </div>
                    }
                </div>

                {!props.hideCartButton &&
                    <button className="cart-btn" onClick={toggleCart}>
                        <span className="counter">
                            {cart ? cart.getItemsQuantity() : 0}
                        </span>
                    </button>
                }
            </div>
            <nav className="nav-bar">
                <nav className="categories-nav-bar">
                    <div className="user-nav mobile-only">
                        {loggedIn ?
                            <>
                                <Link className="link user" to="/my-account">
                                    <img src={userIcon} alt="my account" />
                                    My account
                                </Link>
                                <button className="link" onClick={onLogout}>
                                    Logout
                                </button>
                            </>
                            :
                            <>
                                <Link className="link" to="/sign-in">Sign in</Link>
                                <Link className="link" to="/sign-up">Create an account</Link>
                            </>
                        }
                    </div>

                    {loggedIn && isAdmin &&
                        <div ref={adminRef} className={
                            "admin-customer-container " +
                            (showAdminDropdown ? "open" : "")
                        }>
                            <button
                                className="link"
                                onClick={() =>
                                    toggleAdminDropdown(!showAdminDropdown)
                                }
                            >
                                Customer Account
                            </button>
                            <div className="admin-dropdown">
                                {logginInAsCustomer ?
                                    <Loader /> :
                                    <UserPicker
                                        ref={userPickerRef}
                                        initialValue={null}
                                        onChangeSelectedItem={confirmAdminLogin}
                                    />
                                }
                            </div>
                        </div>
                    }

                    {content && getHeaderLinks().map((hl: any, i) => {
                        return hl.isGroup ?
                            <div key={i} className="dropdown-link">
                                {hl.link.title ?
                                    getAnchorFor(hl.link, i)
                                    :
                                    <div className="link">{hl.link}</div>
                                }
                                <div className="dropdown">
                                    {hl.children.map((c: any, j: number) =>
                                        getAnchorFor(c, j)
                                    )}
                                </div>
                            </div>
                            :
                            getAnchorFor(hl.link, i)
                    })}
                </nav>
            </nav>
        </header>
    );
}