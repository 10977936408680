import React, { 
    forwardRef, 
    ForwardRefRenderFunction, 
    RefObject, 
    useEffect, 
    useImperativeHandle, 
    useRef, 
    useState 
} from "react";

import UserPickerItem from "./item";
import TextField from "../textfield";
import { User } from "../../../models";
import useDebounce from "../../../utils/debounce";
import { useFlash } from "../../../stores/flashStore";
import UserService from "../../../services/UserService";

import loadingIcon from '../../../assets/images/loader-blue.gif';
import userIcon from '../../../assets/images/icons/black-user.svg';
import './styles.scss';

interface PropTypes {
    label?:string;
    initialValue:User|null;
    onChangeSelectedItem?(item:User|null):void;
}

export interface UserPickerRefTypes {
    clear():void;
}

const UserPicker:ForwardRefRenderFunction<
    UserPickerRefTypes, 
    PropTypes
> = (props, ref) => {
    const flash = useFlash();
    const acRef:RefObject<HTMLDivElement> = useRef(null);
    
    const [loading, setLoading] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [resultsList, setResultsList] = useState<any>(null);
    const [showDropdown, setShowDropdown] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<User|null>(props.initialValue);
    const [textFilter, setTextFilter] = useState(props.initialValue?.getAttributeValue('firstname') || "");
    
    useImperativeHandle(ref, () => ({
        clear: () => {
            setTextFilter("");
            setSelectedItem(null);
        }
    }));

    useEffect(() => {
        if (!inputFocused)
            return;

        debouncedFilter(textFilter);
        // eslint-disable-next-line
    }, [inputFocused]);
    
    useEffect(() => {
        if (showDropdown === null)
            return;
            
        debouncedFilter(textFilter);
        // eslint-disable-next-line
    }, [textFilter]);
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const debouncedFilter = useDebounce((text:any) => {
        if (!text) {
            setShowDropdown(false);
            return;
        }

        setShowDropdown(true);
        setLoading(true);
        UserService.list(text)
            .then(data => setResultsList(data.users))
            .catch(errors => {
                console.error(errors);
                flash.show({
                    type: "error",
                    message: errors
                });
            })
            .finally(() => setLoading(false));
    }, 400);

    const handleClickOutside = (event:any) => {
        if (acRef.current && 
            !acRef.current.contains(event.target) && 
            showDropdown
        ) {
            setLoading(false);
            setShowDropdown(false);
        }
    };

    const onSelectItem = (value:User) => {
        setLoading(false);
        setSelectedItem(value);
        setShowDropdown(false);
        props.onChangeSelectedItem && props.onChangeSelectedItem(value);
    };

    const clearSelectedItem = () => {
        setSelectedItem(null);
        props.onChangeSelectedItem && props.onChangeSelectedItem(null);
    };

    return (
        <div 
            ref={acRef}
            className={"user-picker " + 
                (showDropdown ? "search-open" : "")
            }
        >
            <div className="autocomplete-form">
                <label className="title">{props.label}</label>
                {(!selectedItem || !selectedItem.id) &&
                    <TextField 
                        name="q"
                        value={textFilter}
                        onChange={setTextFilter}
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        placeholder="Search by name or email"
                    />
                }
                {selectedItem && selectedItem.id &&
                    <div className="selected-item-container">
                        <div className="selected-item-row">
                            <div className="info" title={selectedItem.getAttributeValue('firstname') || ""}>
                                <img src={userIcon} alt="user" />
                                <span>{selectedItem.getAttributeValue('firstname')} ({selectedItem.email})</span>
                            </div>
                            <div className="cleaner">
                                <button 
                                    type="button" 
                                    onClick={() => clearSelectedItem()}
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                }
                
                {loading && 
                    <div className="loader">
                        <img src={loadingIcon} alt="loading" />
                    </div>
                }
                <div hidden={!showDropdown} className="dropdown">
                    <div className="dropdown-group">
                        <div className="title">Users</div>
                        {loading && 
                            <div className="searcher">Loading...</div>
                        }
                        
                        {!loading && resultsList &&
                        <div className="values">
                            {resultsList.length > 0 ?
                                resultsList.map((value:User, i:number) => {
                                    return (
                                        <UserPickerItem 
                                            key={i} 
                                            item={value}
                                            onSelect={(item:User) => onSelectItem(item)}
                                        />
                                    );
                                }) 
                                :
                                <div className="dropdown-item not-found">
                                    No results.
                                </div>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(UserPicker);