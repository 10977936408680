import UserStorage from "../utils/userStorage";
import { Catcept } from "../models/Catcept/Catcept";
import ErrorHandlingService from "./ErrorHandlingService";
import CatceptRepository from "../repositories/CatceptRepository";

export default class CatceptService {
    static async list(
        nameFilter?: string, 
        onlyRoots?: boolean,
        parentId?: string,
    ): Promise<Catcept[]> {
        try {
            const token = UserStorage.getToken() || "";
            return await CatceptRepository.list(
                nameFilter || "", 
                onlyRoots || false, 
                parentId || "",
                token
            );
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getById(id: string): Promise<Catcept> {
        try {
            const token = UserStorage.getToken() || "";
            return await CatceptRepository.getById(id, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }
}