import CmsEntity from "./CmsEntity";

export default class UploadFile extends CmsEntity {
    public url = "";
    public name = "";
    public preview_url = "";
    
    constructor(data: any = {}) {
        super(data);
        if (!data)
            return;
            
        this.url = data.url;
        this.name = data.name;
        this.preview_url = data.preview_url;
    }
}