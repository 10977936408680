import React from 'react';
import ProductListMenuItem from './item';
import { useHistory } from 'react-router-dom';
import { useMyProductLists } from '../../../../stores/myProductListStore';

interface PropTypes {
    onItemSelect(): void;
}

export default function ProductListsMenu(props: PropTypes) {
    const path = useHistory().location.pathname;
    const productListsState = useMyProductLists();    
    
    const isSelectedListUnderThisSection = (sectionType: string) => {
        if (sectionType === "purchase" && path.includes("product-list/manage"))
            return true;
            
        return productListsState.getByType(sectionType)
            .some(l => l.id && path.includes(l.id));
    };

    return (<>
        <ProductListMenuItem
            type="shopping"
            title="Shopping Lists"
            setMobileMenuOpen={() => props.onItemSelect()}
            initialStateOpen={isSelectedListUnderThisSection("shopping")}
        />
        <ProductListMenuItem
            type="purchase"
            title="Purchase Order Lists"
            setMobileMenuOpen={() => props.onItemSelect()}
            initialStateOpen={isSelectedListUnderThisSection("purchase")}
        />
        <ProductListMenuItem
            type="shared"
            title="Shared Lists"
            setMobileMenuOpen={() => props.onItemSelect()}
            initialStateOpen={isSelectedListUnderThisSection("shared")}
        />
        <ProductListMenuItem
            type="public"
            title="Public Lists"
            setMobileMenuOpen={() => props.onItemSelect()}
            initialStateOpen={isSelectedListUnderThisSection("public")}
        />
    </>);
}