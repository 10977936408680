import Category from "./Category";
import CmsEntity from "./CmsEntity";
import UploadFile from "./UploadFile";

export default class BlogPage extends CmsEntity {
    public slug = "";
    public title = "";
    public content = "";
    public excerpt = "";
    public active = true;
    public image = new UploadFile();
    public image_file:File|undefined;
    public publish_date = new Date();
    public categories = new Array<Category>();

    constructor(data: any = {}) {
        super(data);
        if (!data)
            return;

        this.slug = data.slug;
        this.title = data.title;
        this.active = data.active;
        this.content = data.content;
        this.excerpt = data.excerpt;
        this.image_file = data.image_file;
        this.publish_date = data.publish_date;
        this.image = new UploadFile(data.image);

        if (data.categories)
            this.categories = data.categories.map((c:any) => new Category(c));
    }
}