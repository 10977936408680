
export class PriceList {
  public id: number = 0;
  public name: string = '';
  public createdAt: Date = new Date();

  constructor(data: any = {}) {
      this.id = data.id;
      this.name = data.name;
      this.createdAt = new Date(data.createdAt);
  }
}
