import { createState, useState } from '@hookstate/core';
import GeneralContentPage from '../models/SiteContent/GeneralContentPage';

const contentState: any = createState(null);
const stateWrapper = (state: any) => ({
    get: (): GeneralContentPage => state?.value,
    set: (content: GeneralContentPage) => 
        state?.set((c: GeneralContentPage) => c = content),
});

export const accessContent = () => stateWrapper(contentState);
export const useContent = () => stateWrapper(useState(contentState));