import React from 'react';

import './styles.scss';

interface PropTypes {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    selectedValue?: string;
    onChange: (value: string) => void;
    options: Array<{ key: string, value: string }>
};

export default function Select(props: PropTypes) {
    return (
        <div className="select-fieldset">
            <select
                className={
                    "select " +
                    (!props.selectedValue ? "empty" : "")
                }
                disabled={props.disabled}
                value={props.selectedValue}
                onChange={(e) => props.onChange(e.target.value)}
            >
                {props.options.map((opt: any, i: number) =>
                    <option key={i} value={opt.key}>{opt.value}</option>
                )}
            </select>
            {props.label &&
                <label className="title">
                    {props.label} {props.required ? <span> *</span> : ""}
                </label>
            }
        </div>
    );
};