import React from 'react';
import { Link } from 'react-router-dom';
import { useContent } from '../../stores/siteContentStore';
import CmsLink, { CmsLinkType } from '../../models/SiteContent/CmsLink';

import shopperApprovedIcon from '../../assets/images/shopper.png';
import './styles.scss';

export default function Footer() {
    const contentState = useContent();
    const content = contentState.get();
    const { version: appVersion } = require('../../../package.json');

    const getAnchorFor = (link:CmsLink, idx:number) => {
        if (link.type === CmsLinkType.BLOG)
            return <Link key={idx} to={"/blog/" + link.url}>{link.title}</Link>;
        else if (link.type === CmsLinkType.PAGE)
            return <Link key={idx} to={"/page/" + link.url}>{link.title}</Link>;
                    
        if (link.url.match(/^https?:/)) {
            return <a 
                key={idx} 
                href={link.url} 
                target="_blank"
                rel="noopener noreferrer"
            >
                {link.title}
            </a>;
        } else {
            return <Link key={idx} to={link.url}>{link.title}</Link>;
        }
    };

    return (
        <footer id="footer">
            <div className="links">
                {content && content.footer_links.map((l, i) => 
                    getAnchorFor(l, i)
                )}
            </div>
            <div className="copy" title={"MetaCommerce v" + appVersion}>
                {(new Date()).getFullYear()} &copy; iRemedy Healthcare. All rights reserved
            </div>
            <div className="brands">
                <img src={shopperApprovedIcon} alt="shopper" />
            </div>
        </footer>
    );
}