export class OrderNote {
    public id?: number = 0;
    public note: string = '';
    public email?: string = '';
    public createdAt?: Date = new Date();

    constructor (data: any = {}) {
        this.id = data.id;
        this.email = data.email;
        this.note = data.note;
        this.createdAt = data.timestamp ? new Date(data.timestamp) : new Date();
    }

}