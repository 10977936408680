import React, { useEffect, useState } from 'react';
import MainTemplate from '../../../templates/main';
import InfoCard from '../../../../components/form/info-card';
import AccountLeftMenu, { SubMenuType } from '../../../../components/account-left-menu';
import Table from '../../../../components/form/table';
import Loader from '../../../../components/form/loader';
import Modal from '../../../../components/Modal';
import TextField from '../../../../components/form/textfield';
import Select from '../../../../components/form/select';
import SubmitButton from '../../../../components/form/submit-button';

import './styles.scss';
import BuyerGroupsService from '../../../../services/BuyerGroupsService';
import { PaymentMethod } from '../../../../models/BuyerGroups/PaymentMethod';
import { BuyerGroup } from '../../../../models/BuyerGroups/BuyerGroup';
import { PriceList } from '../../../../models/BuyerGroups/PriceList';
import { useFlash } from '../../../../stores/flashStore';
import { BuyerGroupInput } from '../../../../models/BuyerGroups/BuyerGroupInput';
import { useDialog } from '../../../../stores/dialogStore';

interface IBuyerGroupForm {
    name: string;
    paymentMethods: string[];  // list of payment codes
    priceListId: string;  // assign price list id
}

export default function BuyerGroups() {
    const defaultForm = {
        name: '',
        paymentMethods: [],
        priceListId: '',
    } as IBuyerGroupForm;
    const flash = useFlash();
    const dialog = useDialog()

    const [currentPage, setCurrentPage] = useState(0);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingPrev, setLoadingPrev] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [targetBuyerGroup, setTargetBuyerGroup] = useState<BuyerGroup | null>(null);
    const itemsPerPage = 5;

    const [buyerGroups, setBuyerGroups] = useState<BuyerGroup[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);

    const [createFormData, setCreateFormData] = useState<IBuyerGroupForm>(defaultForm);

    const [editFormData, setEditFormData] = useState<IBuyerGroupForm>(defaultForm);

    const totalPages = Math.ceil(buyerGroups.length / itemsPerPage);

    const currentData = buyerGroups.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    useEffect(() => {
        (async () => {
            try {
                const paymentsMethods = await BuyerGroupsService.paymentMethods()
                setPaymentMethods(paymentsMethods)
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const priceLists = await BuyerGroupsService.priceLists()
                setPriceLists(priceLists)
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const buyerGroups = await BuyerGroupsService.list()
                setBuyerGroups(buyerGroups);
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            } finally {
                setLoading(false)
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (newPage: number) => {
        if (newPage > currentPage) {
            setLoadingNext(true);
        } else {
            setLoadingPrev(true);
        }

        setTimeout(() => {
            setCurrentPage(newPage);
            setLoadingNext(false);
            setLoadingPrev(false);
        }, 500);
    };

    const handleDelete = (buyerGroup: BuyerGroup) => {
        dialog.show({
            type: "confirm",
            title: "Delete buyer group",
            message: "Are you sure you want to delete the buyer group?"
        }).then(async (result) => {
            if (!result)
                return;
            try {
                const status = await BuyerGroupsService.deleteBuyerGroup(buyerGroup.id.toString());
                if (status === false) {
                    throw new Error("Can't delete the buyer group");
                }
                const updatedGroups = buyerGroups.filter((group) => group.id !== buyerGroup.id);
                setBuyerGroups(updatedGroups);
                flash.show({
                    type: 'success',
                    message: "Buyer group deleted successfully"
                })
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            }
        });
    };

    const handleEdit = (buyerGroup: BuyerGroup) => {
        setEditFormData({
            name: buyerGroup.name,
            paymentMethods: buyerGroup.paymentMethods.map(paymentMethod => paymentMethod.code),
            priceListId: buyerGroup.priceList.id.toString(),
        });
        setTargetBuyerGroup(buyerGroup);
        setIsEditModalOpen(true);
    };

    const createModalContent = () => {
        const handleCreateInputChange = (field: string, value: any) => {
            setCreateFormData((prev) => ({ ...prev, [field]: value }));
        };

        const handleCreateSave = async () => {
            setSaveLoading(true);
            if (createFormData.priceListId === '') {
                flash.show({
                    type: 'error',
                    message: "You must select a buyer group price list"
                })
                return;
            }
            if (createFormData.name === '') {
                flash.show({
                    type: 'error',
                    message: "You must add a buyer group name"
                })
                return;
            }
            try {
                const { name, paymentMethods, priceListId } = createFormData;
                const newBuyerGroup = await BuyerGroupsService.createBuyerGroup(priceListId, paymentMethods, name);
                setBuyerGroups(prev => [newBuyerGroup, ...prev]);
                flash.show({
                    type: 'success',
                    message: "Buyer group created successfully"
                })
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            } finally {
                setSaveLoading(false);
                setIsCreateModalOpen(false);
                setCreateFormData(defaultForm);
            }
        };

        return (
            <div className="popup-content">
                <h2>Create Buyer Group</h2>
                <div className='fields'>
                    <TextField
                        required
                        name="buyerGroupName"
                        label="Buyer Group Name"
                        value={createFormData.name}
                        onChange={(value) => handleCreateInputChange('name', value)}
                    />
                    <div className="payment-methods">
                        <label>Payment Methods</label>
                        {paymentMethods.map((paymentMethod, index) => (
                            <div className="checkbox" key={index}>
                                <input
                                    type="checkbox"
                                    id={paymentMethod.code}
                                    checked={createFormData.paymentMethods.includes(paymentMethod.code)}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        const updatedMethods = checked
                                            ? [...createFormData.paymentMethods, paymentMethod.code]
                                            : createFormData.paymentMethods.filter((m) => m !== paymentMethod.code);
                                        handleCreateInputChange('paymentMethods', updatedMethods)
                                    }}
                                />
                                <label htmlFor={paymentMethod.code}>{paymentMethod.title}</label>
                            </div>
                        ))}
                    </div>
                    <Select
                        required
                        label="Price List"
                        selectedValue={createFormData.priceListId}
                        options={[
                            { key: '', value: 'Select an option' },
                            ...priceLists.map(priceList => ({ key: priceList.id.toString(), value: priceList.name })),
                        ]}
                        onChange={(value) => handleCreateInputChange('priceListId', value)}
                    />
                </div>
                <div className="modal-actions">
                    <SubmitButton
                        label="Save"
                        onClick={handleCreateSave}
                        disabled={saveLoading}
                        customClass="btn save-notes-btn"
                    />
                    <button
                        className="btn cancel-btn"
                        onClick={() => setIsCreateModalOpen(false)}
                        disabled={saveLoading}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    };

    const editModalContent = () => {
        const handleEditInputChange = (field: string, value: any) => {
            setEditFormData((prev) => ({ ...prev, [field]: value }));
        };

        const handleEditSave = async () => {
            if (!targetBuyerGroup || !editFormData) return;
            if (editFormData.priceListId === '') {
                flash.show({
                    type: 'error',
                    message: "You must select a buyer group price list"
                })
                return;
            }
            if (editFormData.name === '') {
                flash.show({
                    type: 'error',
                    message: "You must add a buyer group name"
                })
                return;
            }
            setSaveLoading(true);
            try {
                const updatedGroup = new BuyerGroupInput({
                    id: targetBuyerGroup.id,
                    name: editFormData.name,
                    priceListId: editFormData.priceListId,
                    paymentMethods: editFormData.paymentMethods,
                })
                const newBuyerGroup = await BuyerGroupsService.editBuyerGroup(updatedGroup);
                setBuyerGroups(prev => prev.map(buyerGroup => buyerGroup.id !== newBuyerGroup.id ? buyerGroup : newBuyerGroup))
                flash.show({
                    type: 'success',
                    message: 'Buyer group updated successfully'
                })
            } catch (errors: any) {
                flash.show({
                    type: 'error',
                    message: errors.map((e: any) => e.message).join("\n")
                })
            } finally {
                setTargetBuyerGroup(null);
                setSaveLoading(false);
                setIsEditModalOpen(false);
                setEditFormData(defaultForm);
            }
        };

        return (
            <div className="popup-content edit-popup">
                <h2>Edit Buyer Group</h2>
                <div>
                    <TextField
                        required
                        name="buyerGroupName"
                        label="Buyer Group Name"
                        value={editFormData.name}
                        onChange={(value) => handleEditInputChange('name', value)}
                    />
                    <div className='payment-methods'>
                        <label>Payment Methods</label>
                        {paymentMethods.map((paymentMethod, index) => (
                            <div className="checkbox" key={index}>
                                <input
                                    type="checkbox"
                                    id={paymentMethod.code}
                                    checked={editFormData.paymentMethods.includes(paymentMethod.code)}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        const updatedMethods = checked
                                            ? [...editFormData.paymentMethods, paymentMethod.code]
                                            : editFormData.paymentMethods.filter((m) => m !== paymentMethod.code);
                                        handleEditInputChange('paymentMethods', updatedMethods)
                                    }}
                                />
                                <label htmlFor={paymentMethod.code}>{paymentMethod.title}</label>
                            </div>
                        ))}
                    </div>
                    <div className='price-list'>
                        <label>Price List</label>
                        <Select
                            required
                            label="Price List"
                            selectedValue={editFormData.priceListId}
                            options={[
                                { key: '', value: 'Select an option' },
                                ...priceLists.map(priceList => ({ key: priceList.id.toString(), value: priceList.name })),
                            ]}
                            onChange={(value) => handleEditInputChange('priceListId', value)}
                        />
                    </div>
                </div>
                <div className="modal-actions">
                    <SubmitButton
                        label="Save"
                        onClick={handleEditSave}
                        disabled={saveLoading}
                        customClass="btn save-notes-btn"
                    />
                    <button
                        className="btn cancel-btn"
                        onClick={() => setIsEditModalOpen(false)}
                        disabled={saveLoading}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    };

    return (
        <MainTemplate>
            <div className="buyer-groups-container">
                <AccountLeftMenu
                    title="Buyer Groups"
                    submenu={SubMenuType.ManageCustomers}
                    subtitle="Buyer Groups"
                />

                <div className="details">
                    <div className="content">
                        <InfoCard
                            title="Buyer Groups"
                            additionalHeaderInfo={
                                <SubmitButton
                                    label="Create Buyer Group"
                                    onClick={() => setIsCreateModalOpen(true)}
                                />
                            }>
                            <Table
                                headers={[
                                    { label: 'ID' },
                                    { label: 'Name' },
                                    { label: 'Payment Method' },
                                    { label: 'Price List' },
                                    { label: 'Actions' },
                                ]}
                                footer={
                                    <div className="pager">
                                        <div className="pager">
                                            Page #{currentPage + 1}
                                        </div>
                                        <div className="pager">
                                            <button
                                                className="load-more-btn"
                                                disabled={currentPage === 0 || loadingPrev || loading}
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                {(loadingPrev) ? (
                                                    <Loader />
                                                ) : (
                                                    <span>Previous Page</span>
                                                )}
                                            </button>
                                            <button
                                                className="load-more-btn"
                                                disabled={currentPage === totalPages - 1 || loadingNext || loading}
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                {(loadingNext) ? (
                                                    <Loader />
                                                ) : (
                                                    <span>Next Page</span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                }
                            >
                                {loading && <Loader />}
                                {!loading && currentData.map((buyerGroup) => (
                                    <tr key={buyerGroup.id}>
                                        <td>{buyerGroup.id}</td>
                                        <td>{buyerGroup.name}</td>
                                        <td>
                                            {buyerGroup.paymentMethods
                                                .map((paymentMethod) => paymentMethod.title)
                                                .join(", ")}
                                        </td>
                                        <td>{buyerGroup.priceList.name}</td>
                                        <td className='actions'>
                                            <button
                                                className="btn edit-btn"
                                                onClick={() => handleEdit(buyerGroup)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="btn delete-btn"
                                                onClick={() => handleDelete(buyerGroup)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </Table>
                        </InfoCard>
                    </div>
                </div>
                {isCreateModalOpen && (
                    <Modal
                        content={createModalContent()}
                        onClose={() => setIsCreateModalOpen(false)}
                        open={isCreateModalOpen}
                    />
                )}
                {isEditModalOpen && (
                    <Modal
                        content={editModalContent()}
                        onClose={() => setIsEditModalOpen(false)}
                        open={isEditModalOpen}
                    />
                )}
            </div>
        </MainTemplate>
    );
}