import { BuyerGroup } from "../models/BuyerGroups/BuyerGroup";
import { BuyerGroupInput } from "../models/BuyerGroups/BuyerGroupInput";
import { PaymentMethod } from "../models/BuyerGroups/PaymentMethod";
import { PriceList } from "../models/BuyerGroups/PriceList";
import BuyerGroupsRepository from "../repositories/BuyerGroupsRepository";
import UserStorage from "../utils/userStorage";
import ErrorHandlingService from "./ErrorHandlingService";


export default class BuyerGroupsService {
    static async list(): Promise<BuyerGroup[]> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.list(token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async paymentMethods(): Promise<PaymentMethod[]> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.paymentMethods(token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async priceLists(): Promise<PriceList[]> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.priceLists(token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }
    
    static async createBuyerGroup(
        priceListId: string,
        paymentMethods: string[],
        name: string
    ): Promise<BuyerGroup> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.createBuyerGroup(token, priceListId, paymentMethods, name);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async editBuyerGroup(buyerGroup: BuyerGroupInput): Promise<BuyerGroup> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.editBuyerGroup(token, buyerGroup);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async deleteBuyerGroup(buyerGroupId: string): Promise<boolean> {
        const token = UserStorage.getToken();
        try {
            return await BuyerGroupsRepository.deleteBuyerGroup(token, buyerGroupId);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }
}