// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/alert.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/icons/confirm.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".dialog-overlay {\n  top: 0;\n  left: 0;\n  z-index: 10;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  position: fixed;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(5, 9, 21, 0.6);\n}\n.dialog-overlay .dialog-content {\n  width: 100%;\n  display: flex;\n  max-width: 400px;\n  position: relative;\n  flex-direction: column;\n  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 110% 130%;\n  background-color: #fff;\n}\n.dialog-overlay .dialog-content.confirm {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.dialog-overlay .dialog-content.alert, .dialog-overlay .dialog-content.confirm {\n  padding: 0;\n  border: none;\n  margin-bottom: 0;\n  border-radius: 0;\n}\n.dialog-overlay .dialog-content .header {\n  color: #fff;\n  padding: 10px 15px;\n  background-color: #0c629f;\n}\n.dialog-overlay .dialog-content .body {\n  display: flex;\n  min-height: 80px;\n  padding: 10px 20px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n}\n.dialog-overlay .dialog-content .footer {\n  display: flex;\n  padding: 10px 10px 20px;\n  justify-content: center;\n}\n.dialog-overlay .dialog-content .footer button {\n  border: none;\n  color: #fff;\n  min-width: 80px;\n  cursor: pointer;\n  min-height: 35px;\n  appearance: none;\n  margin-right: 5px;\n  transition: all 0.3s;\n  background-color: #1663A0;\n  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.2);\n}\n.dialog-overlay .dialog-content .footer button:last-child {\n  margin-right: 0;\n  margin-left: 5px;\n}\n.dialog-overlay .dialog-content .footer button.secondary {\n  background-color: #666;\n}\n.dialog-overlay .dialog-content .footer button:hover {\n  transform: scale(1.1);\n  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);\n}\n\n@media (max-width: 540px) {\n  .dialog-overlay .dialog-content {\n    width: 90%;\n  }\n}", ""]);
// Exports
module.exports = exports;
