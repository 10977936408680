import React from 'react';

import loaderIcon from '../../../assets/images/loader-white.gif';
import './styles.scss';

interface PropTypes {
    icon?: string;
    label?: string;
    title?: string;
    loading?: boolean;
    disabled?: boolean;
    customClass?: string;
    customLoaderIcon?: string;
    type?: "button" | "submit" | "reset" | undefined;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

export default function SubmitButton(props: PropTypes) {
    return (
        <button
            title={props.title}
            onClick={props.onClick}
            type={props.type || "submit"}
            disabled={props.loading || props.disabled}
            className={
                "submit-btn btn " +
                (props.customClass || "") +
                (props.loading ? " loading" : "")
            }
        >
            {props.loading ?
                <img
                    alt="loading"
                    src={props.customLoaderIcon || loaderIcon}
                />
                :
                <>
                    {props.icon && <img alt="" src={props.icon} />}
                    {props.label && <div>{props.label}</div>}
                </>
            }
        </button>
    );
}
