import React from 'react';
import { NavLink } from 'react-router-dom';

interface PropTypes {
    slug: string;
    title: string;
    setMobileMenuOpen(state: boolean): any;
};

export default function BusinessSettingsMenuItem(props: PropTypes) {
    return (
        <NavLink
            className="link"
            activeClassName="active"
            onClick={() => props.setMobileMenuOpen(false)}
            to={"/my-account/business-settings/" + props.slug}
        >
            {props.title}
        </NavLink>
    );
}