import settings from '../../settings';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

const httpLink = createHttpLink({
    uri: settings.env.REACT_APP_CMS_API,
});

const authLink = setContext((_, { headers }) => {
    return { headers: { ...headers } }
});

const CmsApiClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default CmsApiClient;