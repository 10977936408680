import { Link, useHistory } from "react-router-dom";
import React, { RefObject, useEffect, useRef, useState } from "react";

import Dropdown from "../../form/dropdown";
import ProductItemAutocomplete from "./item";
import TextField from "../../form/textfield";
import useDebounce from "../../../utils/debounce";
import UserStorage from "../../../utils/userStorage";
import { useSidebar } from "../../../stores/sidebarStore";
import ProductService from "../../../services/ProductService";
import { Product, Provider, SearchParameter } from "../../../models";
import { getCategoriesString, getFiltersString, getQueryString, getTagsString } from "../../../utils/URLUtils";

import searchIcon from '../../../assets/images/icons/search.svg';
import loadingIcon from '../../../assets/images/loader-blue.gif';
import './styles.scss';

interface TypeProps {
    placeholder?: string;
    sourceFilter?: Provider;
    hideFiltersButton?: boolean;
    preventSubmission?: boolean;
    showFiltersDropdown?: boolean;
    itemActionButtonLabel?: string;
    renderLayout?: "header" | "page";
    allowUnavailableSelection?: boolean;
    itemActionCallback?(product: Product): void;
    onSelectProduct?(selectedProduct: Product, isGroup?: boolean): void;
}

export default function ProductSearchAutocomplete(props: TypeProps) {
    const history = useHistory();
    const sidebarState = useSidebar();
    const acRef: RefObject<HTMLDivElement> = useRef(null);
    const queryString = getQueryString(history.location.search);
    const filters = getFiltersString(history.location.search);
    const filtersString = filters ? filters.split(";") : [];
    const filterSidebarState = sidebarState.getStateFor("filter");
    const searchOptions = [
        { key: "available-only", value: "Available Products", mobileLabel: "Available" },
        { key: "iremedy-only", value: "Shipped by iRemedy", mobileLabel: "iRemedy" },
        { key: "all", value: "All Products", mobileLabel: "All" }
    ];

    const [loading, setLoading] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [resultsList, setResultsList] = useState<any>(null);
    const [isIremedyGroup, setIsIremedyGroup] = useState(false);
    const [showDropdown, setShowDropdown] = useState<boolean>();
    const [textFilter, setTextFilter] = useState(queryString || "");
    const [tagsString, setTagsString] = useState(getTagsString(history.location.search));
    const [catceptId, setCatceptId] = useState(getCategoriesString(history.location.search));
    const [selectedSearchOption, setSelectedSearchOption] = useState(
        (filtersString.includes("available-only") && "available-only") ||
        (filtersString.includes("iremedy-only") && "iremedy-only") ||
        (filtersString.includes("all") && "all") ||
        searchOptions[0].key
    );

    useEffect(() => {
        const user = UserStorage.get();
        if (!user) {
            setIsIremedyGroup(true);
            return;
        }

        setIsIremedyGroup(
            user.groups.some(group => group.code === "iRemedy-Retail")
        );
    }, []);

    useEffect(() => {
        setTimeout(() => setShowDropdown(false), 401);
        if (!history.location.search)
            setTextFilter("");

        setTagsString(getTagsString(history.location.search));
        setCatceptId(getCategoriesString(history.location.search));
        // eslint-disable-next-line
    }, [history.location.search]);

    useEffect(() => {
        if (!inputFocused)
            return;

        debouncedFilter(textFilter, props.sourceFilter);
        // eslint-disable-next-line
    }, [inputFocused]);

    useEffect(() => {
        if (showDropdown === undefined)
            return;

        debouncedFilter(textFilter, props.sourceFilter);
        // eslint-disable-next-line
    }, [textFilter]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const debouncedFilter = useDebounce((text: any, source?: Provider) => {
        if (!text) {
            setShowDropdown(false);
            return;
        }

        setLoading(true);
        setShowDropdown(true);
        const searchParameters = new SearchParameter(
            text,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            catceptId || undefined,
            (selectedSearchOption === "available-only" && ["Available"]) || undefined,
            (selectedSearchOption === "iremedy-only" && "iremedy") || undefined,
        );
        if (source)
            searchParameters.sourceFilterParameters = [source.id];

        ProductService.typeahead(searchParameters)
            .then(setResultsList)
            .catch(errors => console.error(errors))
            .finally(() => setLoading(false));
    }, 400);

    const handleClickOutside = (event: any) => {
        if (acRef.current &&
            !acRef.current.contains(event.target) &&
            showDropdown
        ) {
            setLoading(false);
            setShowDropdown(false);
        }
    };

    const onSubmit = (event?: React.SyntheticEvent) => {
        event?.preventDefault();
        if (props.preventSubmission)
            return;

        setLoading(false);
        setShowDropdown(false);

        if (resultsList && resultsList.length === 1) {
            const id = findProductIdWithExactQueryMatchInsideVariants(resultsList[0]);
            if (id) {
                history.push(`/${id}`);
                return;
            }
        }

        let query = `/search?query=${textFilter}`;
        
        if (catceptId)
            query += `&category=${catceptId}`;
        
        if (isIremedyGroup)
            filtersString.push("available");

        if (filtersString.length > 0)
            query += `&filters=${filtersString.join(";")}`;

        if (tagsString?.length > 0)
            query += `&tags=${tagsString.join(",")}`;

        history.push(query);
    };

    const findProductIdWithExactQueryMatchInsideVariants = (product: Product) => {
        if (!textFilter || !product)
            return null;

        var matchId = product.variantIds?.find(
            v => v.toLowerCase() === textFilter.toLowerCase()
        );

        return matchId;
    };

    const getLink = (product: Product) => {
        if (!textFilter) {
            if (!product.variantIds || product.variantIds.length <= 1)
                return `/${product.id}`;

            return `/${product.id}?g=true`;
        }

        var productMatch = product.variantIds?.find(v =>
            v.toLowerCase() === textFilter.toLowerCase()
        );

        if (productMatch)
            return `/${productMatch}`;

        const hasVariants = product.variantIds && product.variantIds.length > 1;
        return `/${product.id}${hasVariants ? "?g=true" : ""}`;
    };

    const onSelectProduct = (product: Product, hasVariants?: boolean) => {
        setShowDropdown(false);
        props.onSelectProduct 
            ? props.onSelectProduct(product, hasVariants)
            : history.push(getLink(product));
    };

    const toggleFilter = () => {
        sidebarState.setStateFor("filter", { open: !filterSidebarState.open });
    };

    const removeTag = (tag: string) => {
        tagsString.splice(tagsString.findIndex(t => t === tag), 1);
        setTagsString([...tagsString]);
        onSubmit(undefined);
    };

    return (
        <div
            ref={acRef}
            className={"autocomplete-container " +
                (showDropdown ? "search-open " : "") +
                (props.renderLayout === "page" ? "page-layout" : "")
            }
        >
            <form autoComplete="off" onSubmit={onSubmit}>
                {!props.hideFiltersButton &&
                    <button
                        type="button"
                        onClick={toggleFilter}
                        className="filter-btn"
                    >
                        <i className="filter-icon"></i>
                        <span>Filters</span>
                        {filterSidebarState.actives > 0 &&
                            <div className="counter">{filterSidebarState.actives}</div>
                        }
                    </button>
                }

                {props.showFiltersDropdown &&
                    <Dropdown
                        options={searchOptions}
                        selectedKey={selectedSearchOption}
                        customClass={(inputFocused ? "input-focused" : "")}
                        onChange={(id: string) =>
                            setSelectedSearchOption(id)
                        }
                    />
                }

                {tagsString && tagsString.length > 0 &&
                    <div className={
                        (textFilter || loading 
                            ? "tags-container shifted" 
                            : "tags-container"
                        )
                    }>
                        {tagsString.map((tag, i) =>
                            <div key={i} className="tag">
                                <span>{tag}</span>
                                <i onClick={() => removeTag(tag)}>x</i>
                            </div>
                        )}
                    </div>
                }

                {props.sourceFilter &&
                    <div className={
                        (textFilter || loading 
                            ? "custom-filters-container shifted" 
                            : "custom-filters-container"
                        )
                    }>
                        <div className={
                            "custom-filter " + 
                            (props.sourceFilter.displayName === "iremedy" 
                                ? "iremedy" 
                                : ""
                            )
                        }>
                            {props.sourceFilter.displayName === "iremedy"
                                ? "iRemedy" 
                                : props.sourceFilter.displayName
                            }
                        </div>
                    </div>
                }

                <TextField
                    value={textFilter}
                    onChange={setTextFilter}
                    name="product-search-field"
                    onBlur={() => setInputFocused(false)}
                    onFocus={() => setInputFocused(true)}
                    placeholder={props.placeholder || "Search"}
                />

                {textFilter && !loading &&
                    <div className="cleaner">
                        <button type="button" onClick={() => setTextFilter("")}>
                            X
                        </button>
                    </div>
                }

                {loading &&
                    <div className="loader">
                        <img src={loadingIcon} alt="loading" />
                    </div>
                }
                <button type="submit" className="search-btn">
                    <span>Search</span>
                    <img src={searchIcon} alt="search" />
                </button>
                <div hidden={!showDropdown} className="dropdown">
                    {loading &&
                        <div className="searcher">Searching...</div>
                    }
                    {!loading && resultsList && <>
                        <div className="dropdown-group">
                            <div className="title">Products</div>
                            {resultsList.length > 0 ?
                                resultsList.map((product: Product, i: number) => {
                                    return (
                                        <ProductItemAutocomplete
                                            key={i}
                                            item={product}
                                            searchQuery={textFilter}
                                            onSelect={hasVariants => 
                                                onSelectProduct(product, hasVariants)
                                            }
                                            onActionButtonClick={props.itemActionCallback &&
                                                (() => {
                                                    setShowDropdown(false);
                                                    props.itemActionCallback?.(product);
                                                })
                                            }
                                            actionButtonLabel={
                                                props.itemActionButtonLabel
                                            }
                                            allowUnavailableSelection={
                                                props.allowUnavailableSelection
                                            }
                                        />
                                    );
                                })
                                :
                                <div className="dropdown-item not-found">No results.</div>
                            }
                        </div>
                        {resultsList.length > 0 && !props.preventSubmission &&
                            <div className="dropdown-footer">
                                <Link
                                    to={"/search?query=" + textFilter}
                                    onClick={() => setShowDropdown(false)}
                                >
                                    See all results for "{textFilter}"
                                </Link>
                            </div>}
                    </>}
                </div>
            </form>
        </div>
    );
}