import React, { RefObject, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';

import Loader from "../../../form/loader";
import settings from "../../../../settings";
import { CartItem } from "../../../../models";
import useDebounce from "../../../../utils/debounce";
import CartService from "../../../../services/CartService";
import { currencyFormat } from "../../../../utils/currencyFormatter";

import notFoundImage from '../../../../assets/images/no-image.jpg';
import './styles.scss';

export default function CartListItem(props: any) {
    const rowRef: RefObject<HTMLDivElement> = useRef(null);
    const item = new CartItem(props.item);

    const [qty, setQty] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [updatingProduct, setUpdatingProduct] = useState(false);

    useEffect(() => {
        setQty(item.quantity.toString());
    }, [item.quantity]);

    const removeProductFromCart = () => {
        if (rowRef && rowRef.current)
            rowRef.current?.classList.add("deleted");

        setLoading(true);
        CartService
            .removeItem(item.grokitId)
            .catch(() => {
                setLoading(false);
                rowRef.current?.classList.remove("deleted");
            });
    };

    const debouncedUpdateProductQuantity = useDebounce((quantity: number) => {
        setUpdatingProduct(true);
        CartService
            .updateItem(item.grokitId, quantity)
            .finally(() => setUpdatingProduct(false));
    }, 400);

    return (
        <div className="cart-item-row" ref={rowRef}>
            <div className="cart-item-content">
                <Link to={`/${item.grokitId}`} className="image-container">
                    <img
                        alt={item.name}
                        onError={(e: React.SyntheticEvent<HTMLImageElement>) =>
                            e.currentTarget.src = notFoundImage
                        }
                        src={`${settings.env.REACT_APP_IMAGE_BASE_URI}/${item.image}`}
                    />
                </Link>
                <div className="info">
                    <Link to={`/${item.grokitId}`} className="title-container">
                        <h3 className="title">{item.name}</h3>
                    </Link>
                    <div className="price">{currencyFormat(item.price)}</div>
                    <div className="quantity">
                        <label>Qty: </label>
                        <input
                            min="1"
                            value={qty}
                            type="number"
                            disabled={updatingProduct}
                            onChange={(e: any) => {
                                const quantity = parseInt(e.currentTarget.value);
                                if (isNaN(quantity)) {
                                    setQty("");
                                } else {
                                    setQty(quantity.toString());
                                    debouncedUpdateProductQuantity(quantity);
                                }
                            }}
                        />
                    </div>
                </div>
                {updatingProduct ?
                    <Loader /> :
                    <button
                        className="remove-btn"
                        onClick={removeProductFromCart}
                    />
                }
            </div>
            {loading &&
                <div className="cart-row-loading"></div>
            }
        </div>
    );
}