import { gql } from '@apollo/client';
import HomePage from '../models/SiteContent/HomePage';
import cmsClient from './graphql-clients/CmsApiClient';
import GeneralContentPage from '../models/SiteContent/GeneralContentPage';

export default class SiteContentRepository {
    static async getGeneralContent():Promise<GeneralContentPage> {            
        const query = gql`
            query {
                general {
                    id
                    created_at
                    updated_at
                    published_at
                    header_links
                    footer_links
                    free_shipping_enabled
                    show_verified_supply_badge
                }
            }
        `;

        const data:any = await cmsClient
            .query({ 
                query, 
                fetchPolicy: "network-only" 
            })
            .then(result => result.data);

        return new GeneralContentPage(data.general);
    }
    
    static async getHome(token: string):Promise<HomePage> {
        const query = gql`
            query {
                homePage {
                    id
                    hero_text
                    site_title
                    created_at
                    updated_at
                    top_content
                    published_at
                    hero_sub_text
                    bottom_content
                    site_description
                    hero_image {
                        url
                    }
                    banner_image {
                        url
                    }
                    featured_posts {
                        id
                        slug
                        title
                        excerpt
                        image {
                            url
                        }
                    }
                    featured_grid
                }
            }
        `;
        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };
        const data:any = await cmsClient
            .query({ query, context })
            .then(result => result.data);

        return new HomePage(data.homePage);
    }
}