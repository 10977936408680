import { PaymentMethods } from "./PaymentMethods";

export class PaymentMethod {
  public code: string = '';
  public title: string = '';
  public methods: PaymentMethods[] = [];

  constructor(data: any = {}) {
      this.code = data.code;
      this.title = data.title;

      for (const method of data.methods) {
        this.methods.push(new PaymentMethods(method));
      }
  }
}
