import React, { FC, ReactNode, useEffect, useState } from 'react';
import Loader from '../loader';

import './styles.scss';

interface PropTypes {
    sorting?:boolean;
    footer?:ReactNode;
    clickable?:boolean;
    selectable?:boolean;
    headers:Array<HeaderPropTypes>;
    onSort?:(key:string, asc:boolean) => void;
    onToggleSelection?:(selected:boolean) => void;
    sortedBy?:any;
};

interface HeaderPropTypes {
    key?:string; 
    label:string; 
    sortable?:boolean;
};

const Table:FC<PropTypes> = (props) => {
    let sortedBy = {}
    if (props.sortedBy) {
        sortedBy = {
            key: props.sortedBy.key,
            state: props.sortedBy.direction === 'asc'
        }
    }

    const [selected, setSelected] = useState(false);
    const [sortingBy, setSortingBy] = useState<any>(sortedBy);

    useEffect(() => {
        props.onToggleSelection && 
            props.onToggleSelection(selected);
        // eslint-disable-next-line
    }, [selected]);

    const sort = (key:string) => {
        if (sortingBy.key === key)
            sortingBy.state = !sortingBy.state;            
        else
            sortingBy.state = false;

        setSortingBy({...sortingBy, key});
        props.onSort && props.onSort(key, sortingBy.state);
    };

    const getSortingClassName = (key:string) => {
        var className = "sortable";
        if (sortingBy.key === key)
            className += " active";
        if (sortingBy.state)
            className += " asc";

        return className;
    }

    return (
        <div className="table">
            <table className={props.clickable ? "clickable" : ""}>
                <thead>
                    <tr>
                        {props.selectable &&
                            <td>
                                <input 
                                    type="checkbox"
                                    checked={selected}
                                    onChange={(e:any) => 
                                        setSelected(e.currentTarget.checked)
                                    }
                                />
                            </td>
                        }
                        {props.headers.map((header:HeaderPropTypes, i:number) =>
                            <td 
                                key={i}
                                className={
                                    header.sortable ? 
                                    getSortingClassName(header.key || "") : ""
                                }
                                onClick={() => 
                                    header.sortable && 
                                    header.key && 
                                    sort(header.key)
                                }
                            >
                                {header.label}
                                {header.sortable &&
                                    (sortingBy.key === header.key && props.sorting ?
                                        <Loader />
                                        :
                                        <div className="caret"></div>
                                    )                                    
                                }
                            </td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.children}
                </tbody>
            </table>
            {props.footer}
        </div>
    );
};

export default Table;