import CmsEntity from "./CmsEntity";

export default class Category extends CmsEntity {
    public slug = "";
    public title = "";
    
    constructor(data: any = {}) {
        super(data);
        if (!data)
            return;

        this.slug = data.slug;
        this.title = data.title;
    }
}