import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CartListItem from "./list-item";
import Loader from "../../form/loader";
import TextField from "../../form/textfield";
import { Cart, CartItem } from "../../../models";
import SubmitButton from "../../form/submit-button";
import { useCart } from "../../../stores/cartStore";
import CartStorage from "../../../utils/cartStorage";
import { useFlash } from "../../../stores/flashStore";
import CartService from "../../../services/CartService";
import { useSidebar } from "../../../stores/sidebarStore";
import { currencyFormat } from "../../../utils/currencyFormatter";
import { ErrorMessage } from "../../../services/ErrorHandlingService";
import { CartItemDiffAction } from "../../../models/Cart/CartItemDiff";
import { OrderInfoOption as BusinessOption } from "../../../models";

import './styles.scss';
import UserService from "../../../services/UserService";
import UserStorage from "../../../utils/userStorage";

export default function CartSideBar() {
    const flash = useFlash();
    const cartState = useCart();
    const cart = cartState.get();
    const sidebarState = useSidebar();
    const cartSidebarState = sidebarState.getStateFor("cart");

    const [couponCode, setCouponCode] = useState("");
    const [updatingCoupon, setUpdatingCoupon] = useState(false);
    const [couponErrors, setCouponErrors] = useState<ErrorMessage[]>([]);
    const [glCodeConfig, setGlCodeConfig] = useState<BusinessOption>();
    const [glCode, setGlCode] = useState<string>();
    const [location, setLocation] = useState<string>();
    const [locationConfig, setLocationConfig] = useState<BusinessOption>();
    const [refNumberConfig, setRefNumberConfig] = useState<BusinessOption>();
    const [refNumber, setRefNumber] = useState<string>();
    const [isFetched, setIsFetched] = useState<boolean>(false);

    const updateBusinessInfo = async (
        key: string,
        value: string,
    ) => {
        await CartService.setBusinessInfo(key, value);
    };

    useEffect(() => {
        if (!cart) return;
        const timeoutId = setTimeout(async () => {
            glCode && glCode !== cart?.businessInfo.glCode && await updateBusinessInfo("glCode", glCode);
            location && location !== cart?.businessInfo.location && await updateBusinessInfo("location", location);
            refNumber && refNumber !== cart?.businessInfo.referenceNumber &&  await updateBusinessInfo("referenceNumber", refNumber);
        }, 500)

        return () => clearTimeout(timeoutId);
    }, [glCode, refNumber, location, cart])

    useEffect(() => {
        if (UserStorage.hasToken() && cart && !isFetched) {
            setIsFetched(true);
            loadBusinessInfo();
        }
        // eslint-disable-next-line
    }, [cart, isFetched]);

    const loadBusinessInfo = async () => {
        const company = await UserService.getCompany();

        if (!company)
            return;
        
        const poNumberValue = 
                company?.getAttributeValue('business-info-po-number')
        if (poNumberValue) {
            const refOptions = new BusinessOption(JSON.parse(poNumberValue));
            setRefNumberConfig(refOptions);
            if (refOptions.default && !cart.businessInfo.referenceNumber) {
                await updateBusinessInfo("referenceNumber", refOptions.default);
            }
            setRefNumber(cart.businessInfo.referenceNumber || refOptions.default);
        }

        const glCodeValue = 
            company?.getAttributeValue('business-info-gl-code');
        if (glCodeValue) {
            const glCodeValues = new BusinessOption(JSON.parse(glCodeValue));
            setGlCodeConfig(glCodeValues);
            if (glCodeValues.default && !cart.businessInfo.glCode) {
                await updateBusinessInfo("glCode", glCodeValues.default);
            }
            setGlCode(cart.businessInfo.glCode || glCodeValues.default);
        }

        const locationValue = 
            company?.getAttributeValue('business-info-location');
        if (locationValue) {
            const locationValues = new BusinessOption(JSON.parse(locationValue));
            setLocationConfig(locationValues);
            if (locationValues.default && !cart.businessInfo.location) {
                await updateBusinessInfo("location", locationValues.default);
            }
            setLocation(cart.businessInfo.location || locationValues.default);
        }
    };

    useEffect(() => {
        if (cart)
            return;

        const cartToken = CartStorage.getToken();
        if (cartToken) {
            CartService.get()
                .then(checkCartStatus)
                .catch((errors:ErrorMessage[]) => {
                    // TODO: replace CART_NOT_FOUND with enum
                    if (errors.some((e:ErrorMessage) => e.code === "CART_NOT_FOUND")) {
                        CartService.create().then(() => CartService.get());
                    } else {
                        flash.show({
                            type: "error",
                            message: errors[0]?.message
                        });
                    }
                });
        } else {
            CartService.create()
                .then(() => CartService.get().then(checkCartStatus))
                .catch((errors:ErrorMessage[]) => {
                    // TODO: replace UNEXPECTED_ERROR with enum
                    if (errors.some((e:ErrorMessage) => e.code === "UNEXPECTED_ERROR")) {
                        CartService.create().then(() => CartService.get());
                    } else {
                        flash.show({
                            type: "error",
                            message: errors[0]?.message
                        });
                    }
                });
        }
        // eslint-disable-next-line
    }, []);

    const checkCartStatus = (cartData:Cart) => {
        if (
            cartData?.itemDiffs?.some(i => 
                i.action === CartItemDiffAction.UNAVAILABLE
            )
        ) {
            flash.show({
                delayInMs: 5000,
                type: "warning",
                message: "Some products were removed " + 
                    "from your cart due to unavailability."
            });
        }

        if (!cartData || cartData.isActive === false)
            CartService.create().then(() => CartService.get());
    }

    const closeCart = () => sidebarState.setStateFor("cart", { open: false });

    const applyCoupon = () => {
        setCouponErrors([]);
        setUpdatingCoupon(true);
        CartService.addCoupon(couponCode)
            .catch(errors => setCouponErrors(errors))
            .finally(() => setUpdatingCoupon(false));
    };

    const removeCoupon = (couponCode: string) => {
        setCouponErrors([]);
        setUpdatingCoupon(true);
        CartService.deleteCoupons([couponCode])
            .then(() => {
                (document.querySelector(".coupon-container input") as any).focus();
            })
            .catch(errors => setCouponErrors(errors))
            .finally(() => setUpdatingCoupon(false));
    };

    return (        
        <div className={"side-bar cart " + (cartSidebarState.open ? "open" : "")}>
            <div className="cart-container">
                <div className="title">Shopping Cart</div>
                <div className="popover">  
                    {!cart ? 
                        <Loader />
                    :
                    <>
                        <div className="header">
                            <div className="items-count">
                                <strong>{cart.getItemsQuantity()}</strong> items
                            </div>
                            <div className="total-info">
                                <div>Total</div>
                                <div>{currencyFormat(cart.totals.grand)}</div>
                            </div>
                            <Link 
                                to={cart.items.length > 0 ? "/checkout" : "#"}
                                className={cart.items.length > 0 ? "" : "disabled"}
                            >
                                Go to checkout
                            </Link>
                        </div>
                        <div className="scroll">
                            <div className="products-list">
                            {cart && cart.items.map((item:CartItem) => (
                                <CartListItem key={item.id} item={item} />
                            ))}
                            </div>                        
                            {cart && cart.items.length > 0 && 
                                <div className="cart-info">
                                    <div className="coupon-container">
                                        <TextField 
                                            label="Discount code"
                                            onChange={setCouponCode}
                                            readOnly={cart.coupons?.length > 0}
                                            value={cart.coupons?.length > 0 ? 
                                                cart.coupons[0].code : couponCode
                                            }
                                        />
                                        <SubmitButton
                                            disabled={
                                                cart.coupons?.length <= 0 && 
                                                !couponCode
                                            }
                                            loading={updatingCoupon}
                                            label={cart.coupons?.length > 0 ? 
                                                "Remove" : "Apply"
                                            }
                                            onClick={() => 
                                                cart.coupons?.length > 0 ? 
                                                    removeCoupon(cart.coupons[0].code) : applyCoupon()
                                            }
                                        />
                                        {couponErrors.length > 0 && 
                                            <div className="coupon-errors">
                                                {couponErrors[0].message}
                                            </div>
                                        }
                                    </div>
                                    <div className="business-settings">
                                        {refNumberConfig?.isEnabled && <div className="input-container">
                                            <span>PO#:</span>
                                            <TextField
                                                label="PO#"
                                                onChange={setRefNumber}
                                                value={refNumber}
                                                required={refNumberConfig?.required}
                                            />
                                        </div>}
                                        {glCodeConfig?.isEnabled && <div className="input-container">
                                            <span>GL code:</span>
                                            <TextField
                                                label="GL code"
                                                onChange={setGlCode}
                                                value={glCode}
                                                required={glCodeConfig?.required}
                                            />
                                        </div>}
                                        {locationConfig?.isEnabled && <div className="input-container">
                                            <span>Location:</span>
                                            <TextField
                                                label="Location"
                                                onChange={setLocation}
                                                value={location}
                                                required={locationConfig?.required}
                                            />
                                        </div>}
                                    </div>
                                    <div className="cart-totals">
                                        <div className="item">
                                            <div>Cart Subtotal</div>
                                            <div>
                                                {currencyFormat(cart.totals.subtotal)}
                                            </div>
                                        </div>
                                        {cart.hasShippingInfo &&
                                        !!cart.totals.shipping &&
                                            <div className="item">
                                                <div>Shipping</div>
                                                <div>
                                                {cart.totals.shipping === 0 
                                                    ? "Free"
                                                    : currencyFormat(cart.totals.shipping)
                                                }
                                                </div>
                                            </div>
                                        }
                                        <div className="item">
                                            <div>Tax</div>
                                            <div>
                                            {currencyFormat(cart.totals.tax)}
                                            </div>
                                        </div>
                                        {!!cart.totals.discount &&
                                            <div className="item">
                                                <div>Discount</div>
                                                <div>
                                                {currencyFormat(cart.totals.discount)}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="view-cart-btn">
                                        <Link 
                                            to={cart.items.length > 0 ? "/cart" : "#"}
                                        >
                                            View and Edit Cart
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    }                    
                    <button className="close-btn" onClick={closeCart}>
                        x
                    </button>
                </div>
            </div>
        </div>
    );
}