import React, { lazy, Suspense } from 'react';
import Loader from '../components/form/loader';
import UserStorage from '../utils/userStorage';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ManageCustomers from './my-account/manage-customers/customers';
import BuyerGroups from './my-account/manage-customers/buyer-groups';

const Home = lazy(() => import('./home'));
const Blog = lazy(() => import('./blog'));
const Tags = lazy(() => import('./tags'));
const Cart = lazy(() => import('./cart-view'));
const SignIn = lazy(() => import('./sign-in'));
const SignUp = lazy(() => import('./sign-up'));
const CmsPageView = lazy(() => import('./cms'));
const Checkout = lazy(() => import('./checkout'));
const ProductView = lazy(() => import('./product'));
const ContactPage = lazy(() => import('./contact'));
const AboutUsPage = lazy(() => import('./about-us'));
const ThankYouPage = lazy(() => import('./thank-you'));
const BlogPostPage = lazy(() => import('./blog/post'));
const Authenticate = lazy(() => import('./authenticate'));
const MyQuotes = lazy(() => import('./my-account/quotes'));
const CheckoutQuote = lazy(() => import('./checkout-quote'));
const SearchResults = lazy(() => import('./search-results'));
const MyAddress = lazy(() => import('./my-account/address'));
const Security = lazy(() => import('./my-account/security'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const CreatePassword = lazy(() => import('./create-password'));
const MyOrders = lazy(() => import('./my-account/orders/list'));
const ProductList = lazy(() => import('./my-account/product-list'));
const AddressDetails = lazy(() => import('./my-account/address/details'));
const MyOrdersDetails = lazy(() => import('./my-account/orders/details'));
const MyQuotesDetails = lazy(() => import('./my-account/quotes/details'));
const SecurityDetails = lazy(() => import('./my-account/security/details'));
const ProductListManager = lazy(() => import('./my-account/product-list/manager'));
const StoredPaymentMethods = lazy(() => import('./my-account/payment-methods/list'));
const PaymentMethodDetails = lazy(() => import('./my-account/payment-methods/details'));
const SharedAccounts = lazy(() => import('./my-account/business-settings/shared-accounts'));
const SharedSettings = lazy(() => import('./my-account/business-settings/shared-settings'));
const OrderInfoOptions = lazy(() => import('./my-account/business-settings/order-info-options'));
const ProductPreferences = lazy(() => import('./my-account/business-settings/product-preferences'));
const OrderNotifications = lazy(() => import('./my-account/business-settings/order-notifications'));
const NewSharedAccount = lazy(() => import('./my-account/business-settings/shared-accounts/details'));
const SalesAccounts = lazy(() => import('./my-account/sales-accounts'));

const MasterOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
                (UserStorage.isMaster() ? 
                    <Component {...props} /> :
                    <Redirect to="/" />
                )
            : 
            (props.location && props.location.pathname !== "/" ? 
                document.location.href = `/sign-in?url=${props.location.pathname}` : 
                <Redirect to="/sign-in" />
            )
        }
    />
);

const CompanyAdministrativeOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
                UserStorage.isCompany() || 
                UserStorage.isCompanyManager() ? 
                    <Component {...props} /> :
                    <Redirect to="/" />
            : 
            (props.location && props.location.pathname !== "/" ? 
                document.location.href = `/sign-in?url=${props.location.pathname}` : 
                <Redirect to="/sign-in" />
            )
        }
    />
);

const CompanyEmployeeOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
                (UserStorage.isCompanyEmployee() ? 
                    <Component {...props} /> :
                    <Redirect to="/" />
                )
            : 
            (props.location && props.location.pathname !== "/" ? 
                document.location.href = `/sign-in?url=${props.location.pathname}` : 
                <Redirect to="/sign-in" />
            )
        }
    />
);

const CompanyOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
                (UserStorage.isCompany() ? 
                    <Component {...props} /> :
                    <Redirect to="/" />
                )
            : 
            (props.location && props.location.pathname !== "/" ? 
                document.location.href = `/sign-in?url=${props.location.pathname}` : 
                <Redirect to="/sign-in" />
            )
        }
    />
);

const PrivateOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
            <Component {...props} /> 
            : 
            (props.location && props.location.pathname !== "/" ? 
                document.location.href = `/sign-in?url=${props.location.pathname}` : 
                <Redirect to="/sign-in" />
            )
        }
    />
);

const PublicOnlyRoute:React.FC<any> = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props =>
            UserStorage.hasToken() ?
            <Redirect to="/" />
            : 
            <Component {...props} /> 
        }
    />
);

const Router = () => (
    <BrowserRouter>
        <Suspense 
            fallback={<Loader />}
        >
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/search" component={SearchResults} />
                <Route exact path="/blog/category/:categoryKey" component={Blog} />
                <Route exact path="/blog/tag/:tagKey" component={Blog} />
                <Route exact path="/blog/:key" component={BlogPostPage} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/checkout-quote/:cartId" component={CheckoutQuote} />                
                <Route exact path="/thank-you" component={ThankYouPage} />
                <Route exact path="/authenticate/:token" component={Authenticate} />
                <Route exact path="/about-us" component={AboutUsPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route path="/page/:key" component={CmsPageView} />
                <Route path="/create-password/:token?" component={CreatePassword} />
                
                <CompanyAdministrativeOnlyRoute exact path="/my-account/business-settings/shared-accounts/new" component={NewSharedAccount} />
                <CompanyAdministrativeOnlyRoute exact path="/my-account/business-settings/shared-accounts" component={SharedAccounts} />
                <CompanyAdministrativeOnlyRoute exact path="/my-account/business-settings/order-info" component={OrderInfoOptions} />                
                
                <CompanyOnlyRoute exact path="/my-account/business-settings/notifications" component={OrderNotifications} />                
                <CompanyOnlyRoute exact path="/my-account/business-settings/shared-settings" component={SharedSettings} />
                <CompanyOnlyRoute exact path="/my-account/business-settings/product-preferences" component={ProductPreferences} />

                <MasterOnlyRoute exact path="/my-account/sales-accounts" component={SalesAccounts} />
                <MasterOnlyRoute exact path="/tags" component={Tags} />
                <MasterOnlyRoute exact path="/my-account/manage-customers/buyer-groups" component={BuyerGroups} />
                <PrivateOnlyRoute exact path="/my-account/manage-customers/customers" component={ManageCustomers} />
                
                <CompanyEmployeeOnlyRoute exact path="/my-account/product-list/manage" component={ProductListManager} />

                <PrivateOnlyRoute exact path="/my-account" component={Security} />
                <PrivateOnlyRoute exact path="/my-account/edit" component={SecurityDetails} />
                <PrivateOnlyRoute exact path="/my-account/orders" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/orders/open" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/orders/backorders" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/orders/completed" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/orders/canceled" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/orders/partial" component={MyOrders} />
                <PrivateOnlyRoute exact path="/my-account/quotes" component={MyQuotes} />
                <PrivateOnlyRoute exact path="/my-account/product-list/:id?" component={ProductList} />                
                <PrivateOnlyRoute path="/my-account/orders/:id" component={MyOrdersDetails} />
                <PrivateOnlyRoute path="/my-account/quotes/:id" component={MyQuotesDetails} />
                <PrivateOnlyRoute exact path="/my-account/addresses" component={MyAddress} />
                <PrivateOnlyRoute path="/my-account/addresses/:id" component={AddressDetails} />
                <PrivateOnlyRoute exact path="/my-account/payment-methods" component={StoredPaymentMethods} />
                <PrivateOnlyRoute exact path="/my-account/payment-methods/cards" component={StoredPaymentMethods} />
                <PrivateOnlyRoute exact path="/my-account/payment-methods/accounts" component={StoredPaymentMethods} />
                <PrivateOnlyRoute path="/my-account/payment-methods/:type/new" component={PaymentMethodDetails} />
                
                <PublicOnlyRoute path="/forgot-password" component={ForgotPassword} />
                <PublicOnlyRoute path="/sign-up/:type/:sponsorSlug" component={SignUp} />
                <PublicOnlyRoute path="/sign-up/:type" component={SignUp} />
                <PublicOnlyRoute path="/sign-up" component={SignUp} />              
                <PublicOnlyRoute path="/sign-in" component={SignIn} />
                
                <Route path="/:id+" component={ProductView} />
            </Switch>
        </Suspense>
    </BrowserRouter>
);

export default Router;