import { gql } from '@apollo/client';
import { UserSegment } from '../models/User/UserSegment';
import CodApiClient from './graphql-clients/CodApiClient';
import { Address, Npi, User, UserAttributeDto } from '../models';
import ErrorHandlingService from '../services/ErrorHandlingService';
import { UserList } from '../models/User/UserList';

export default class UserRepository {
    static async login(email: string, password: string): Promise<string> {
        const mutation = gql`
            mutation login($email: String!, $password: String!) {
                login (email: $email, password: $password) {
                    token
                }
            }
        `;

        const variables = { email, password };

        const data: any = await CodApiClient
            .mutate({ mutation, variables })
            .then((result: any) => result.data);


        return data.login.token;
    }

    static async ghostLogin(email: string, adminToken: string): Promise<string> {
        const mutation = gql`
            mutation ghostLogin($email:String!) {
                ghostLogin(email:$email) {
                    token
                }
            }
        `;

        const variables = { email };

        const context = {
            headers: { "Authorization": adminToken }
        };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);


        return data.ghostLogin.token;
    }

    static async getByToken(token: string): Promise<User> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const query = gql`
            query {
                userByToken {
                    id
                    email
                    slug
                    role {
                        id
                        name
                        code
                        permissions {
                            id
                            name
                            code
                            position
                        }
                    }
                    userAttributes  {
                        id
                        attribute {
                            id
                            name
                            code
                            isRequired
                            isUnique
                            defaultValue
                        }
                        value
                        userAttributeList {
                            id
                            items {
                                id
                                attributeGroup {
                                    id
                                    name
                                    code
                                    position
                                }
                                attribute {
                                    id
                                    name
                                    code
                                    type
                                }
                                value                
                            }
                        }
                    }
                    groups {
                        id
                        name
                        code
                    }
                    segments {
                        id
                        name
                        code
                    }
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                fetchPolicy: "no-cache"
            })
            .then(result => result.data);

        return new User(data.userByToken);
    }

    static async getBySlug(slug: string): Promise<User> {
        if (!slug)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const query = gql`
            query userBySlug($slug:String!){
                userBySlug (slug: $slug) {
                    id
                    email
                    slug
                    role {
                        id
                        name
                        code
                    }
                }
            }
        `;

        const variables = { slug }

        const data: any = await CodApiClient
            .query({
                query,
                variables,
                fetchPolicy: "no-cache"
            })
            .then(result => result.data);

        return new User(data.userBySlug);
    }

    public static async getAscendent (id: string, roleCode: string, token: string): Promise<User> {
        const query = gql`
          query userAscendent ($id: String!, $roleCode: String!) {
            userAscendent (id: $id, roleCode: $roleCode) {
                id
                email
                userAttributes  {
                    id
                    attribute {
                        id
                        name
                        code
                        isRequired
                        isUnique
                        defaultValue
                    }
                    value
                    userAttributeList {
                        id
                        items {
                            id
                            attributeGroup {
                                id
                                name
                                code
                                position
                            }
                            attribute {
                                id
                                name
                                code
                                type
                            }
                            value                
                        }
                    }
                }
            }
          }
        `
    
        const variables: any = { id, roleCode }
    
        const context = { headers: {} }
        context.headers = { Authorization: `${token}` }
    
        const data :any = await CodApiClient
          .query({
            query: query,
            variables: variables,
            context: context,
            fetchPolicy: 'network-only'
          })
          .then((result :any) => {
            return result.data.userAscendent
          })
          .catch((error:any) => {
            console.log(error)
            throw error.graphQLErrors[0]
          })
    
        return new User(data)
      }

    static async listDescendents(myId: string, token: string): Promise<User[]> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const query = gql`
            query userDescendents($id: String!, $levelsDown: Int!) {
                userDescendents(id: $id, levelsDown: $levelsDown) {
                    id
                    email
                    slug
                    role {
                        id
                        name
                        code
                        permissions {
                            id
                            name
                            code
                        }
                    }
                    userAttributes  {
                        attribute {
                            name
                            code
                        }
                        value
                    }     
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const variables = {
            id: myId,
            levelsDown: 2
        };

        const data: any = await CodApiClient
            .query({
                query,
                variables,
                context,
                fetchPolicy: "network-only"
            })
            .then(result => result.data);

        return data.userDescendents.map((u: any) => new User(u));
    }

    static async listSponsored(myId: string, token: string): Promise<User[]> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const query = gql`
            query {
                userSponsoreds {
                    id
                    email
                    slug
                    role {
                        id
                        name
                        code
                        permissions {
                            id
                            name
                            code
                        }
                    }
                    userAttributes  {
                        attribute {
                            name
                            code
                        }
                        value
                    }     
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                fetchPolicy: "network-only"
            })
            .then(result => result.data);

        return data.userSponsoreds.map((u: any) => new User(u));
    }

    static async list(search: string, token: string, pagination?: { page:number, limit:number }): Promise<UserList> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const query = gql`
            query users($search:String!, $pagination: InputPagingationType) {
                users(search:$search, pagination: $pagination) {
                    users {
                        id
                        email
                        slug
                        role {
                            id
                            name
                            code
                            permissions {
                                id
                                name
                                code
                                position
                            }
                        }
                        userAttributes  {
                            id
                            attribute {
                                id
                                name
                                code
                                isRequired
                                isUnique
                                defaultValue
                            }
                            value
                            userAttributeList {
                                id
                                items {
                                    id
                                    attributeGroup {
                                        id
                                        name
                                        code
                                        position
                                    }
                                    attribute {
                                        id
                                        name
                                        code
                                        type
                                    }
                                    value                
                                }
                            }
                        }
                        groups {
                            id
                            name
                            code
                        }
                        segments {
                            id
                            name
                            code
                        }
                    }
                    pageInfo {
                        count
                        page
                        limit
                        totalPages
                    }
                }
            }
        `;

        const variables = { search, pagination };

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: "network-only"
            })
            .then(result => result.data?.users);

        return new UserList(data);
    }

    static async add(
        user: User,
        forceChangePassword = false,
        createSlug = false,
        defaultGroupCode = '',
        token: string
    ): Promise<User> {
        const mutation = gql`
            mutation addUser($user: UserInputType!, $forceChangePassword: Boolean, $createSlug: Boolean) {
                addUser (user: $user, forceChangePassword: $forceChangePassword, createSlug: $createSlug) {
                    id
                    email
                    slug
                    role {
                        id
                        name
                        code
                        permissions {
                            id
                            name
                            code
                            position
                        }
                    }
                    userAttributes  {
                        attribute {
                            id
                            name
                            code
                            isRequired
                            isUnique
                            defaultValue
                        }
                        value
                    }
                    groups {
                        id
                        name
                        code
                    }
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const variables: any = {
            user: {
                email: user.email,
                password: user.password,
                roleCode: user.role.code,
                parentId: user.parent?.id,
                sponsorId: user.sponsor?.id,
                userAttributes: user.userAttributes.map(ua => {
                    return {
                        value: ua.value,
                        attributeCode: ua.attributeCode,
                        attributeGroupCode: ua.attributeGroupCode,
                    }
                })
            },
            forceChangePassword,
            createSlug
        };

        if (defaultGroupCode !== undefined && defaultGroupCode !== '') {
            variables.user.groups = [defaultGroupCode]
        }

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);


        return data.addUser;
    }

    static async update(user: User, token: string): Promise<User> {
        const mutation = gql`
            mutation updateUser($user: UserInputType!) {
                updateUser(user: $user) {
                    id
                    email
                    role {
                        id
                        name
                        code
                        permissions {
                            id
                            name
                            code
                            position
                        }
                    }
                    userAttributes  {
                        attribute {
                            id
                            name
                            code
                            isRequired
                            isUnique
                            defaultValue
                        }
                        value
                    }
                    groups {
                        id
                        name
                        code
                    }
                    segments {
                        id
                        name
                        code
                    }
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const variables = {
            user: {
                id: user.id,  // validation master user only
                email: user.email,
                password: user.password,
                roleCode: user.role.code,
                parentId: user.parent?.id,
                segments: user.segments?.map(s => s.code),
                userAttributes: user.userAttributes.map(ua => ({
                    value: ua.value,
                    attributeCode: ua.attributeCode,
                    attributeGroupCode: ua.attributeGroupCode,
                }))
            }
        };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);

        return new User(data.updateUser);
    }

    static async updateUserAttributes(
        userAttributes: UserAttributeDto[], 
        token: string
    ): Promise<void> {
        const mutation = gql`
            mutation updateUser($user: UserInputType!) {
                updateUser(user: $user) {
                    id
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const variables = {
            user: { userAttributes }
        };

        await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);
    }

    static async delete(id: string, token: string): Promise<User> {
        const mutation = gql`
            mutation deleteUser($id: String!) {
                deleteUser (id: $id) 
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const variables = { id };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);


        return data.deleteUser;
    }

    static async changePassword(
        currentPassword: string,
        newPassword: string,
        token: string
    ): Promise<boolean> {
        const mutation = gql`
            mutation changePassword(
                $currentPassword:String!, 
                $newPassword:String!, 
                $newPasswordConfirmation:String!
            ) {
                changePassword (
                    currentPassword:$currentPassword, 
                    newPassword:$newPassword, 
                    newPasswordConfirmation:$newPasswordConfirmation
                )
            }
        `;

        const variables = {
            "currentPassword": currentPassword,
            "newPassword": newPassword,
            "newPasswordConfirmation": newPassword
        };
        const context = {
            headers: { 'Authorization': token }
        };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);

        return data.changePassword === true;
    }

    static async normalizeAddress(address: Address, token: string): Promise<void> {
        const query = gql`
            query validateAddress($address: InputAddressType){
                validateAddress(address: $address) {
                    street
                    complement
                    city
                    regionCode
                    countryId
                    postcode        
                }
            }
        `

        const variables: any = { address };

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: "no-cache"
            })
            .then(result => result.data)
        // .catch(error => console.log('normalize-error', error));

        const uspsAddress = new Address(data.validateAddress)

        if (address.street !== uspsAddress.street) {
            address.street = uspsAddress.street
        }

        if (address.complement !== uspsAddress.complement) {
            address.complement = uspsAddress.complement
        }

        if (address.city !== uspsAddress.city) {
            address.city = uspsAddress.city
        }

        if (address.regionCode !== uspsAddress.regionCode) {
            address.regionCode = uspsAddress.regionCode
        }

        if (address.postcode !== uspsAddress.postcode) {
            address.postcode = uspsAddress.postcode
        }
    }

    static async saveAddress(address: Address, token: string): Promise<Address> {
        const mutation = gql`
            mutation updateUser ($user: UserInputType!) {
                updateUser (user: $user) {
                    id
                }
            }
        `;

        const userAttributeList = {
            id: address.id,
            items: [
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-alias",
                    value: address.alias
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-street",
                    value: address.street
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-complement",
                    value: address.complement
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-city",
                    value: address.city
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-state",
                    value: address.regionCode
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-country",
                    value: address.countryId
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-postcode",
                    value: address.postcode
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-sameAsShipping",
                    value: address.sameAsBilling.toString()
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-defaultBilling",
                    value: address.defaultBilling.toString()
                },
                {
                    attributeGroupCode: "address-book-address",
                    attributeCode: "address-defaultShipping",
                    value: address.defaultShipping.toString()
                },
                {
                    attributeGroupCode: "address-book-personal-info",
                    attributeCode: "address-firstname",
                    value: address.firstname
                },
                {
                    attributeGroupCode: "address-book-personal-info",
                    attributeCode: "address-lastname",
                    value: address.lastname
                },
                {
                    attributeGroupCode: "address-book-personal-info",
                    attributeCode: "address-phone",
                    value: address.telephone
                },
                {
                    attributeGroupCode: "address-book-personal-info",
                    attributeCode: "address-company",
                    value: address.company
                },
            ]
        }

        const variables = {
            user: {
                userAttributes: [
                    {
                        attributeGroupCode: 'address-book',
                        attributeCode: 'address',
                        userAttributeList
                    }
                ]
            }
        };

        const context = {
            headers: { 'Authorization': token }
        };

        await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return new Address({});
    }

    static async deleteAddress(address: Address, token: string): Promise<void> {
        const mutation = gql`
            mutation deleteUserAttributes ($userAttributeIds: [String!]!) {
                deleteUserAttributes (userAttributeIds: $userAttributeIds) {
                    id
                }
            }
        `;

        const variables = {
            userAttributeIds: [address.id]
        }

        const context = {
            headers: { 'Authorization': token }
        };

        await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

    }

    static async npiMatchAddress(address: Address, token: string): Promise<Npi[]> {
        const query = gql`
            query npiMatchAddress ($address: InputAddressType) {
                npiMatchAddress (address: $address) {
                    id       
                    npi
                    type
                    name 
                    address {      
                        alias
                        street
                        complement
                        city
                        regionCode
                        countryId
                        postcode
                        telephone
                    }
                    normalizedAddress
                    normalizedAddressSimple
                }
            }   
        `;

        const variables = { address }

        const context = {
            headers: { 'Authorization': token }
        };

        const data = await CodApiClient
            .query({ query, variables, context, fetchPolicy: 'network-only' })
            .then(result => result.data)

        const npis: Npi[] = []
        for (const npiData of data.npiMatchAddress) {
            npis.push(new Npi(npiData))
        }

        return npis
    }

    static async proAddressVerification(npiId: string, token: string): Promise<boolean> {
        const mutation = gql`
            mutation proAddressVerification ($npiId: String!) {
                proAddressVerification (npiId: $npiId)        
            }   
        `;

        const variables = { npiId }

        const context = {
            headers: { 'Authorization': token }
        };

        const data = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return data.proAddressVerification === true
    }

    static async changeNewsletterSubscription(
        isSubscribed: boolean,
        token: string
    ): Promise<boolean> {
        const mutation = gql`
            mutation userNewsletterSubscription($isSubscribed: Boolean!) {
                userNewsletterSubscription(isSubscribed: $isSubscribed)
            }
        `;

        const variables = { isSubscribed };
        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return data.userNewsletterSubscription;
    }

    static async emailExists(email: string): Promise<boolean> {
        const query = gql`
            query userEmailExists($email: String!) {
                userEmailExists(email: $email) 
            }
        `;

        const variables = { email }

        const data: any = await CodApiClient
            .query({
                query,
                variables,
                fetchPolicy: "network-only"
            })
            .then(result => result.data);

        return data.userEmailExists;
    }

    static async listUserSegments(): Promise<UserSegment[]> {
        const query = gql`
            query userSegments {
                userSegments {
                    id
                    name
                    code        
                }
            }
        `;

        const data: any = await CodApiClient
            .query({ query })
            .then(result => result.data);

        return data.userSegments?.map((us: any) => new UserSegment(us));
    }

    static async sendResetPasswordToken(email: string): Promise<boolean> {
        const mutation = gql`
            mutation forgotPassword($email: String!) {
                forgotPassword (email: $email)
            }
        `;

        const variables = { email };

        const data: any = await CodApiClient
            .mutate({ mutation, variables })
            .then(result => result.data);

        return data.sendTokenToResetCustomerPassword === true;
    }

    static async resetPassword(email: string, token: string, newPassword: string): Promise<boolean> {
        const variables = { token, email, newPassword, newPasswordConfirmation: newPassword };

        const mutation = gql`
            mutation recoveryPassword($token: String! $email: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
                recoveryPassword (token: $token, email: $email, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation)
            }
        `;

        const data: any = await CodApiClient
            .mutate({ mutation, variables })
            .then(result => result.data);

        return data.recoveryPassword === true;
    }

    static async becomeCompany(token: string): Promise<string> {
        const mutation = gql`
            mutation becomeCompany ($token: String!) {
                becomeCompany (token: $token) 
            }
        `;

        const variables = { token };
        const context = {
            headers: { 'Authorization': token }
        };

        const data: any = await CodApiClient
            .mutate({ mutation, variables, context })
            .then((result: any) => result.data);


        return data.becomeCompany;
    }
}