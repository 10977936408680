import { Pagination } from "../Pagination";
import { User } from "./User";

export class UserList {
  public users: User[] = [];
  public pagination: Pagination = { count: 0, limit: 20, page: 1, totalPages: 0 };

  constructor(data: any = {}) {
    this.pagination = data.pageInfo as Pagination;
    for (const user of data.users) {
      this.users.push(new User(user));
    }
  }
}
