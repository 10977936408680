import React from "react";
import { User } from "../../../models";

import './styles.scss';

interface PropTypes {
    item:User;
    onSelect(item:User):void;
}

export default function UserPickerItem(props:PropTypes) {
    return (
        <div 
            className="dropdown-item"
            onClick={() => props.onSelect(props.item)}
        >
            <div className="info" title={props.item.getAttributeValue("firstname") || ""}>
                {props.item.getAttributeValue("firstname")}{" "}
                {props.item.getAttributeValue("lastname")}{" "}
                ({props.item.email})
            </div>
        </div>
    );
}