export default class CmsEntity {
    public id = "";
    public created_at = new Date();
    public updated_at = new Date();
    public published_at = new Date();
    
    constructor(data: any = {}) {
        if (!data)
            return;
            
        this.id = data.id;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.published_at = new Date(data.published_at);
    }
}