import { gql } from '@apollo/client';
import { BuyerGroup } from '../models/BuyerGroups/BuyerGroup';
import UhmpcApiClient from './graphql-clients/UhmpcApiClient';
import { PaymentMethod } from '../models/BuyerGroups/PaymentMethod';
import { PriceList } from '../models/BuyerGroups/PriceList';
import { BuyerGroupInput } from '../models/BuyerGroups/BuyerGroupInput';

export default class BuyerGroupsRepository {
    public static async list(token: string): Promise<BuyerGroup[]> {
        const query = gql`
            query buyerGroups {
                buyerGroups {
                    id
                    name
                    priceList {
                        id
                        name
                        createdAt
                    }
                    paymentMethods {
                        code
                        title
                        methods {
                            code
                            title
                        }
                    }
                }
            }
        `;

        const context = {
            headers: { 'Authorization': token }
        };

        const variables = {};

        const data: any = await UhmpcApiClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: 'network-only'
            })
            .then(result => result.data?.buyerGroups);

        return data.map((buyerGroup: any) => new BuyerGroup(buyerGroup));
    }

    public static async paymentMethods(token: string): Promise<PaymentMethod[]> {
        try {
            const query = gql`
                query PaymentMethods {
                    paymentMethods {
                        code
                        title
                        methods {
                            code
                            title
                        }
                    }
                }
            `;

            const context = {
                headers: { 'Authorization': token }
            };

            const variables = {};

            const data: any = await UhmpcApiClient
                .query({
                    query,
                    context,
                    variables,
                    fetchPolicy: 'network-only'
                })
                .then(result => result.data?.paymentMethods);

            return data.map((paymentMethod: any) => new PaymentMethod(paymentMethod));
        } catch (error: any) {
            throw error;
        }
    }

    public static async priceLists(token: string): Promise<PriceList[]> {
        try {
            const query = gql`
                query PriceLists {
                    priceLists {
                        id
                        name
                        createdAt
                    }
                }
            `;

            const context = {
                headers: { 'Authorization': token }
            };

            const variables = {};

            const data: any = await UhmpcApiClient
                .query({
                    query,
                    context,
                    variables,
                    fetchPolicy: 'network-only'
                })
                .then(result => result.data?.priceLists);

            return data.map((priceList: any) => new PriceList(priceList));
        } catch (error: any) {
            throw error;
        }
    }

    public static async createBuyerGroup(
        token: string,
        priceListId: string,
        paymentMethods: string[],
        name: string
    ): Promise<BuyerGroup> {
        try {
            const mutation = gql`
                mutation CreateBuyerGroup($priceListId: ID!, $paymentMethods: [String!]!, $name: String!) {
                    createBuyerGroup(priceListId: $priceListId, paymentMethods: $paymentMethods, name: $name) {
                        id
                        name
                        priceList {
                            id
                            name
                            createdAt
                        }
                        paymentMethods {
                            id
                            code
                            title
                            methods {
                                code
                                title
                            }
                        }
                    }
                }
            `;

            const context = {
                headers: { 'Authorization': token },
            };

            const variables = {
                priceListId,
                paymentMethods,
                name,
            };

            const data: any = await UhmpcApiClient
                .mutate({
                    mutation,
                    context,
                    variables,
                })
                .then(result => result.data?.createBuyerGroup);

            return new BuyerGroup(data);
        } catch (error: any) {
            throw error;
        }
    }

    public static async editBuyerGroup(
        token: string,
        buyerGroup: BuyerGroupInput
    ): Promise<BuyerGroup> {
        try {
            const mutation = gql`
                mutation editBuyerGroup($buyerGroup: BuyerGroupInput!) {
                    editBuyerGroup(buyerGroup: $buyerGroup) {
                        id
                        name
                        priceList {
                            id
                            name
                            createdAt
                        }
                        paymentMethods {
                            code
                            title
                            methods {
                                code
                                title
                            }
                        }
                    }
                }
            `;
    
            const context = {
                headers: { Authorization: token },
            };
    
            const variables = {buyerGroup: Object.assign({}, buyerGroup)};
    
            const data: any = await UhmpcApiClient
                .mutate({
                    mutation,
                    context,
                    variables,
                })
                .then((result) => result.data?.editBuyerGroup);
    
            return new BuyerGroup(data);
        } catch (error: any) {
            throw error;
        }
    }

    public static async deleteBuyerGroup(token: string, buyerGroupId: string): Promise<boolean> {
        try {
            const mutation = gql`
                mutation deleteBuyerGroup($buyerGroupId: ID!) {
                    deleteBuyerGroup(buyerGroupId: $buyerGroupId)
                }
            `;
    
            const context = {
                headers: { Authorization: token },
            };
    
            const variables = {
                buyerGroupId,
            };
    
            const data: any = await UhmpcApiClient
                .mutate({
                    mutation,
                    context,
                    variables,
                })
                .then((result) => result.data?.deleteBuyerGroup);
    
            return data as boolean;
        } catch (error: any) {
            throw error;
        }
    }
    
}
