import React, { RefObject, useEffect, useRef, useState } from 'react';

import './styles.scss';

interface PropTypes {
    maxHeight?: number;
    disabled?: boolean;
    placeholder?: string;
    selectedKey?: string;
    customClass?: string;
    freezePlaceholder?: boolean;
    onChange(value: string): void;
    options?: Array<{ key: string, value: string, mobileLabel?: string }>
};

export default function Dropdown(props: PropTypes) {
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] =
        useState<{ key: string, value: string, mobileLabel?: string } | undefined>(
            props.options?.find(o => o.key === props.selectedKey)
        );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    useEffect(() => {
        setSelectedOption(
            props.freezePlaceholder ? undefined :
                props.options?.find(o => o.key === props.selectedKey)
        );

    }, [props.selectedKey, props.options, props.freezePlaceholder]);

    const handleClickOutside = (event: any) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            showDropdown
        )
            setShowDropdown(false);
    };

    const onChange = (option: { key: string, value: string }) => {
        setShowDropdown(false);
        if (!props.freezePlaceholder)
            setSelectedOption(option);
        props.onChange(option.key);
    };

    return (
        <div
            ref={dropdownRef}
            className={`dropdown-fieldset ${props.customClass || ""}`}
        >
            <div
                className={"toggle" + (props.disabled ? " disabled" : "")}
                onClick={() => !props.disabled && setShowDropdown(!showDropdown)}
            >
                {selectedOption?.mobileLabel &&
                    <span className="mobile-only">
                        {selectedOption.mobileLabel || props.placeholder}
                    </span>
                }
                <span className={selectedOption?.mobileLabel ? "desktop-only" : ""}>
                    {selectedOption?.value || props.placeholder}
                </span>
            </div>
            <div 
                style={{ maxHeight: props.maxHeight || 200 }}
                className={"options" + (showDropdown ? " active" : "")} 
            >
                {props.options && props.options.map((opt, i: number) =>
                    <div
                        key={i}
                        className="option"
                        onClick={() => onChange(opt)}
                    >
                        {opt.mobileLabel &&
                            <span className="mobile-only">
                                {opt.mobileLabel}
                            </span>
                        }
                        <span className={opt.mobileLabel ? "desktop-only" : ""}>
                            {opt.value}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};