import HomePage from '../models/SiteContent/HomePage';
import ErrorHandlingService from './ErrorHandlingService';
import { accessContent } from '../stores/siteContentStore';
import SiteContentRepository from '../repositories/SiteContentRepository';
import GeneralContentPage from '../models/SiteContent/GeneralContentPage';
import UserStorage from '../utils/userStorage';

export default class SiteContentService  {
    static async getGeneralContent():Promise<GeneralContentPage> {
        try {
            var content = await SiteContentRepository.getGeneralContent();
            accessContent().set(content);
            return content;
        } catch (error) {
            throw ErrorHandlingService.getMessages(error);
        }
    }

    static async getHome():Promise<HomePage> {
        try {
            const token = UserStorage.getToken();
            return await SiteContentRepository.getHome(token);
        } catch (error) {
            throw ErrorHandlingService.getMessages(error);
        }
    }
}