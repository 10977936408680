import { PaymentMethod } from "./PaymentMethod";
import { PriceList } from "./PriceList";

export class BuyerGroup {
    public id: number = 0;
    public name: string = '';
    public priceList: PriceList = new PriceList();
    public paymentMethods: PaymentMethod[] = [];

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.priceList = new PriceList(data.priceList);
        
        for (const paymentMethod of data.paymentMethods) {
            this.paymentMethods.push(new PaymentMethod(paymentMethod));
        }
    }
}
