import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { MyProductList } from '../../../../models';
import UserService from '../../../../services/UserService';
import { useMyProductLists } from '../../../../stores/myProductListStore';
import UserStorage from '../../../../utils/userStorage';

interface PropTypes {
    type: string;
    title: string;
    initialStateOpen?: boolean;
    setMobileMenuOpen(state: boolean): any;
};

export default function ProductListMenuItem(props: PropTypes) {
    const { title, type, initialStateOpen } = props;

    const productListsState = useMyProductLists();
    const productLists = productListsState.getByType(type);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [hasViewPermission, setHasViewPermission] = useState(false);
    const [hasCreatePermission, setHasCreatePermission] = useState(false);
    
    useEffect(() => {
        checkPermission();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (initialStateOpen)
            setIsOpen(true);
        // eslint-disable-next-line
    }, [initialStateOpen]);

    const checkPermission = async () => {
        setHasViewPermission(
            await UserService.hasPermission(`${type}-list-view`)
        );

        setHasCreatePermission(
            await UserService.hasPermission(`${type}-list-add`) && type !== 'shared'
        );
    };

    return (<>
        {hasViewPermission && (
            <div className={"dropdown-link " + (isOpen ? "open" : "")}>
                <div className="link" onClick={() => setIsOpen(!isOpen)}>
                    {title}
                </div>
                <div className="dropdown">
                    {hasCreatePermission &&
                        <NavLink
                            className="link"
                            activeClassName="selected"
                            to={"/my-account/product-list/new" + 
                                (type === "purchase" ? "?type=po" : "")
                            }
                            onClick={() => props.setMobileMenuOpen(false)}
                        >
                            <span>Create list</span>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 490 490" width="16" height="16"><g><g><path fill="#fff" d="M227.8,174.1v53.7h-53.7c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2h53.7v53.7c0,9.5,7.7,17.2,17.2,17.2
                            s17.1-7.7,17.1-17.2v-53.7h53.7c9.5,0,17.2-7.7,17.2-17.2s-7.7-17.2-17.2-17.2h-53.7v-53.7c0-9.5-7.7-17.2-17.1-17.2
                            S227.8,164.6,227.8,174.1z"></path><path fill="#fff" d="M71.7,71.7C25.5,118,0,179.5,0,245s25.5,127,71.8,173.3C118,464.5,179.6,490,245,490s127-25.5,173.3-71.8
                            C464.5,372,490,310.4,490,245s-25.5-127-71.8-173.3C372,25.5,310.5,0,245,0C179.6,0,118,25.5,71.7,71.7z M455.7,245
                            c0,56.3-21.9,109.2-61.7,149s-92.7,61.7-149,61.7S135.8,433.8,96,394s-61.7-92.7-61.7-149S56.2,135.8,96,96s92.7-61.7,149-61.7
                            S354.2,56.2,394,96S455.7,188.7,455.7,245z"></path></g></g>
                            </svg>
                        </NavLink>
                    }
                    {type === "purchase" && UserStorage.isCompanyEmployee() ?
                        <NavLink 
                            className="link" 
                            activeClassName="active" 
                            to="/my-account/product-list/manage"
                        >
                            <div>
                                {UserStorage.isCompanyBuyer() ? "My" : "Manage"} POs
                            </div>
                        </NavLink>
                        :
                        productListsState.get() && 
                        (productLists.length > 0 ?
                            productLists.map((pl: MyProductList,) =>
                                <NavLink
                                    key={pl.id}
                                    className="link"
                                    activeClassName="active"
                                    to={"/my-account/product-list/" + pl.id}
                                    onClick={() => props.setMobileMenuOpen(false)}
                                >
                                    {pl.name}
                                </NavLink>
                            )
                            :
                            <div className="link empty">No available lists</div>
                        )
                    }
                </div>
            </div>
        )}
    </>)
}