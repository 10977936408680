import { gql } from '@apollo/client';
import { Catcept } from '../models/Catcept/Catcept';
import uhmpcClient from './graphql-clients/UhmpcApiClient';


export default class CatceptRepository {
    static async list(
        nameFilter: string,
        onlyRoots: boolean,
        parentId: string,
        token: string
    ): Promise<Catcept[]> {
        const query = gql`
            query listCatceptsSummary(
                $nameFilter: String,
                $isActive: Boolean,
                $noParentId: Boolean!,
                $parentId: String                
            ) {
                listCatceptsSummary(
                    nameFilter: $nameFilter,
                    isActive: $isActive,
                    noParentId: $noParentId,
                    parentId: $parentId
                ) {
                    id
                    name
                }
            }
        `;

        const variables: any = { 
            nameFilter,
            isActive: true,
            noParentId: onlyRoots            
        };

        if (parentId) {
            variables.parentId = parentId
        }

        const context = {
            headers: { "Authorization": token }
        };

        const catceptsSummary = await uhmpcClient
            .query({
                query,
                context,
                variables,
            })
            .then((result: any) => {
                const catcepts: Catcept[] = [];
                result.data?.listCatceptsSummary
                    ?.map((c: any) => catcepts.push(new Catcept(c)));
                return catcepts;
            });
        
        return catceptsSummary;
    }

    static async getById(id: string, token: string): Promise<Catcept> {
        const query = gql`
            query getCatceptDefinition($id: String) {
                getCatceptDefinition(id: $id) {
                    id
                    name
                    children {
                        id
                        name
                        childrenIds
                    }
                }
            }
        `;

        const variables = { id };

        const context: any = {};
        if (token) {
            context.headers = { "Authorization": token }
        }

        const catcept = await uhmpcClient
            .query({
                query,
                context,
                variables,
            })
            .then((result: any) => {
                return new Catcept(
                    result.data?.getCatceptDefinition
                );
            });
        
        return catcept;
    }
}