export class Catcept {
    public id = "";
    public name = "";
    public parentId = "";
    public parentName = "";
    public children: Catcept[] = [];
    public childrenIds: string[] = [];

    constructor(data:any = {})  {
        this.id = data.id;
        this.name = data.name;
        this.parentId = data.parentId;
        this.parentName = data.parentName;
        
        if (data.children) {
            this.children = data.children.map((c: any) => {
                this.childrenIds.push(c.id);
                return new Catcept(c);
            });
        } else if (data.childrenIds) {
            this.childrenIds = data.childrenIds;
        }
    }
}