import { RoleType, User } from '../models';
import { accessUser } from '../stores/userStore';

const storageKey = "@metacommerce-user";
const ghostStorageKey = "@metacommerce-ghost-user";

class UserStorage {
    static hasToken = (): boolean => {
        var token = localStorage.getItem(storageKey);
        return !!token;
    };

    static getToken = (): string => {
        const token = localStorage.getItem(storageKey);
        return token || "";
    };

    static get = (): User | null => {
        const token = UserStorage.getToken();
        if (!token)
            return null;

        const data: any = accessUser().get();
        return new User(data);
    };

    static setToken = (userToken: string): void => {
        localStorage.setItem(storageKey, userToken);
    };

    static getMagentoToken = (): string => {
        const token = UserStorage.getToken();
        if (token === null)
            return "";
        // TODO: Make request to get user by token.
        return "";
        // const data: any = jwt.decode(token);
        // return data?.magentoToken || "";
    };

    static isMaster = (): boolean => {
        const user = UserStorage.get();
        if (!user)
            return false;
        return user.role?.code === RoleType.Master;
    };

    static isCompany = (): boolean => {
        const user = UserStorage.get();
        if (!user)
            return false;

        return user.role?.code === RoleType.Company;
    };

    static isCompanyEmployee = (): boolean => {
        const user = UserStorage.get();
        if (!user)
            return false;

        return user.role?.code === RoleType.Company ||
        user.role?.code === RoleType.Manager ||
        user.role?.code === RoleType.Buyer;
    };

    static isCompanyManager = (): boolean => {
        const user = UserStorage.get();
        if (!user)
            return false;

        return user.role?.code === RoleType.Manager;
    };

    static isCompanyBuyer = (): boolean => {
        const user = UserStorage.get();

        if (!user)
            return false;

        return user.role?.code === RoleType.Buyer;
    };

    static isOnGroup = (code: string): boolean => {
        const user = UserStorage.get();
        if (!user)
            return false;

        return user.groups.some(g => g.code === code);
    }

    static logout = (): void => {
        localStorage.removeItem(storageKey);
    };

    static hasGhostToken = (): boolean => {
        var token = localStorage.getItem(ghostStorageKey);
        return !!token;
    };

    static getGhostToken = (): string => {
        const token = localStorage.getItem(ghostStorageKey);
        return token || "";
    };

    static setGhostToken = (userToken: string): void => {
        const currentToken = UserStorage.getToken();
        localStorage.setItem(ghostStorageKey, currentToken);
        localStorage.setItem(storageKey, userToken);
    };

    static ghostLogout = (): void => {
        const ghostToken = UserStorage.getGhostToken();
        localStorage.removeItem(storageKey);
        localStorage.removeItem(ghostStorageKey);
        localStorage.setItem(storageKey, ghostToken);
    };
}

export default UserStorage;