import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
// @ts-ignore
import ReactInputMask from 'react-input-mask';

import './styles.scss';

interface PropTypes {
    max?: number;
    min?: number;
    name?: string;
    type?: string;
    label?: string;
    errors?: string;
    hint?: ReactNode;
    prompt?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    value?: string | number;
    defaultValue?: string | number;
    onChange?(value: string): void;
    maskPlaceholder?: string | null;
    mask?: string | Array<string | RegExp>;
    onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
    onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
    onKeyPressed?(e: React.KeyboardEvent<HTMLInputElement>): void;
}

export default function TextField(props: PropTypes) {
    const hintRef: RefObject<HTMLDivElement> = useRef(null);
    const promptRef: RefObject<HTMLDivElement> = useRef(null);
    const [showHint, setShowHint] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (event: any) => {
        if (
            hintRef.current &&
            !hintRef.current.contains(event.target) &&
            showHint
        ) {
            setShowHint(false);
        }

        if (
            promptRef.current &&
            !promptRef.current.contains(event.target) &&
            showPrompt
        ) {
            setShowPrompt(false);
        }
    }

    return (
        <div className="fieldset">
            {props.mask ?
                <ReactInputMask
                    id={props.name}
                    mask={props.mask}
                    name={props.name}
                    autoComplete="off"
                    value={props.value}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    readOnly={props.readOnly}
                    required={props.required}
                    disabled={props.disabled}
                    onKeyUp={props.onKeyPressed}
                    defaultValue={props.defaultValue}
                    maskPlaceholder={props.maskPlaceholder}
                    className={"input-field" +
                        (props.value || props.defaultValue ? " filled" : "") +
                        (!props.required ? " non-required" : "") +
                        (props.type === "number" ? " extra-space" : "")
                    }
                    onChange={(evt: any) =>
                        props.onChange && props.onChange(evt.currentTarget.value)
                    }
                />
                :
                <input
                    id={props.name}
                    name={props.name}
                    autoComplete="off"
                    value={props.value}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    required={props.required}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    type={props.type || "text"}
                    onKeyUp={props.onKeyPressed}
                    max={props.max || undefined}
                    min={props.min || undefined}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue}
                    className={"input-field" +
                        (props.type === "number" ? " extra-space" : "") +
                        (props.value || props.defaultValue ? " filled" : "") +
                        (!props.required ? " non-required" : "")
                    }
                    onChange={evt =>
                        props.onChange && props.onChange(evt.currentTarget.value)
                    }
                />
            }
            {props.label &&
                <label htmlFor={props.name}>
                    {props.label} {props.required ? <span> *</span> : ""}
                </label>
            }
            {props.hint &&
                <div className="hint">
                    <button
                        type="button"
                        tabIndex={-1}
                        className="hint-toggle"
                        onClick={() => setShowHint(true)}
                    >
                        ?
                    </button>
                    <div 
                        ref={hintRef} 
                        className={
                            "hint-content " + 
                            (showHint ? "visible" : "")
                        }
                    >
                        {props.hint}
                    </div>
                </div>
            }
            <div
                hidden={!props.errors}
                className="validation-error"
            >
                {props.errors}
            </div>
            {props.prompt &&
                <div className="hint prompt">
                    <small
                        className="text-toggle"
                        onClick={() => setShowPrompt(true)}
                    >
                        (Why<span> is this required</span>?)
                    </small>
                    <div 
                        ref={promptRef} 
                        className={
                            "hint-content " + 
                            (showPrompt ? "visible" : "")
                        }
                    >
                        {props.prompt}
                    </div>
                </div>
            }
        </div>
    );
};