import React from 'react';
import ManageCustomersMenuItem from './item';

interface PropTypes {
    onItemSelect(): void;
}

export default function ManageCustomersMenu(props: PropTypes) {
    return (<>
        <ManageCustomersMenuItem
            slug="buyer-groups"
            title="Buyer Groups"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
        <ManageCustomersMenuItem
            slug="customers"
            title="Customers"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
    </>);
}