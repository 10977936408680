import Loader from "../../form/loader";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFlash } from "../../../stores/flashStore";
import { Catcept } from "../../../models/Catcept/Catcept";
import { useSidebar } from "../../../stores/sidebarStore";
import CatceptService from "../../../services/CatceptService";
import { ErrorMessage } from "../../../services/ErrorHandlingService";

import './styles.scss';

interface PropTypes {
    onChange?(): void;
}

export default function CatceptSideBar(props: PropTypes) {
    const flash = useFlash();
    const history = useHistory();
    const sidebarState = useSidebar();
    const catceptSidebarState = sidebarState.getStateFor("catcept");
    
    const [catcepts, setCatcepts] = useState<Catcept[]>();
    const [navigatingToId, setNavigatingToId] = useState<string>();
    const [catceptPanels, setCatceptPanels] = useState<Catcept[]>([]);
    const [navigatingFromId, setNavigatingFromId] = useState<string>();
    const [loadingCatceptId, setLoadingCatceptId] = useState<string>();

    useEffect(() => {
        CatceptService.list("", true)
            .then((cats) => {
                setCatcepts(cats.sort((a, b) => a.name.localeCompare(b.name)));
            });
    }, []);

    const getCatcept = (id: string, name: string) => {
        setLoadingCatceptId(id);
        CatceptService.list("", false, id)
            .then(catcepts => {
                if (catcepts.length <= 0) {
                    sidebarState.setStateFor("catcept", { open: false });
                    history.push("/search?category=" + id);
                    return;
                }

                const newCatcept = new Catcept({
                    id,
                    name,
                    children: catcepts,
                });
                catceptPanels.push(newCatcept);
                setCatceptPanels([...catceptPanels]);
                setTimeout(() => setNavigatingToId(id), 350);
            })
            .catch((errors: ErrorMessage[]) => {
                flash.show({
                    type: "error",
                    message: errors.map(e => e.message).join("\n"),
                });
            })
            .finally(() => setLoadingCatceptId(undefined));
    };

    const navigateBack = (fromId: string) => {
        if (catceptPanels.length <= 1)
            setNavigatingToId(undefined);
        else
            setNavigatingToId(catceptPanels[catceptPanels.length - 2].id);

        setNavigatingFromId(fromId);
        setTimeout(() => {
            setNavigatingFromId(undefined);
            catceptPanels.splice(catceptPanels.length - 1, 1);
            setCatceptPanels([...catceptPanels]);
        }, 350);
    };

    const toggleMobileMenu = () => {
        if (catceptSidebarState.open) {
            sidebarState.setStateFor("catcept", { open: false });
            return;
        }

        (document.querySelector("html, body") as any).scrollTop = 0;

        sidebarState.setStateFor("catcept", { open: true });
    };

    return (
        <div
            className={
                "side-bar catcept" + 
                (catceptSidebarState.open ? " open" : "")
            }
        >
            <div className="content">
                <div 
                    className={
                        "main-info" + 
                        (navigatingFromId ? " backward" : "") +
                        (navigatingToId ? " shifted" : "")
                    }
                >
                    <div className="title">
                        <span>Departments</span>
                        <button
                            onClick={toggleMobileMenu}
                            className="close-catcepts mobile-only"
                        >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </button>
                    </div>
                    <div className="list">
                        {catcepts?.map(catcept =>
                            <div 
                                key={catcept.id} 
                                className="link" 
                                onClick={() => getCatcept(catcept.id, catcept.name)}
                            >
                                <span>{catcept.name}</span>
                                <div className="chevron">
                                    {loadingCatceptId === catcept.id 
                                        ? <Loader /> 
                                        : <i></i>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {catceptPanels.map(catcept =>
                    <div 
                        key={catcept.id}
                        className={
                            "additional-info" + 
                            (navigatingToId === catcept.id ? " adding" : "") +
                            (navigatingFromId === catcept.id ? " removing" : "")
                        }
                    >
                        <div className="title">
                            <div 
                                className="chevron-back" 
                                onClick={() => navigateBack(catcept.id)}
                            >
                                <i></i>
                            </div>
                            <Link 
                                to={"/search?category=" + catcept.id}
                                onClick={() => sidebarState.setStateFor("catcept", { open: false })}
                            >
                                {catcept.name}
                            </Link>
                        </div>
                        <div className="list">
                            {catcept.children.map(child =>
                                <div 
                                    key={child.id} 
                                    className="link" 
                                    onClick={() => getCatcept(child.id, child.name)}
                                >
                                    <span>{child.name}</span>
                                    <div className="chevron">
                                        {loadingCatceptId === child.id 
                                            ? <Loader /> 
                                            : <i></i>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}