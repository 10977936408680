import CmsLink from "./CmsLink";
import CmsEntity from "./CmsEntity";

export default class GeneralContentPage extends CmsEntity {
    public free_shipping_enabled = false;
    public show_verified_supply_badge = false;
    public header_links = new Array<CmsLink>();
    public footer_links = new Array<CmsLink>();
    
    constructor(data:any = {}) {
        super(data);
        if (!data)
            return;

        this.free_shipping_enabled = data.free_shipping_enabled;
        this.show_verified_supply_badge = data.show_verified_supply_badge;
        if (data.header_links)
            this.header_links = data.header_links.map((c:any) => new CmsLink(c));
        if (data.footer_links)
            this.footer_links = data.footer_links.map((c:any) => new CmsLink(c));
    }
}