export const getQueryString = (queryParams: string): string => {
    if (!queryParams?.includes("?query="))
        return "";

    let query = queryParams
        .replace(/(&|\?)category=.*/gi, "")
        .replace(/(&|\?)filters=.*/gi, "")
        .replace(/(&|\?)manufacturers=.*/gi, "")
        .replace(/(&|\?)tags=.*/gi, "");
    query = query.replace("?query=", "");

    let string;
    try {
        string = decodeURI(query);
    } catch {
        string = "";
    }

    return string;
};

export const getManufacturers = (queryParams: string): string[] => {
    if (!queryParams?.includes("manufacturers="))
        return [];

    let query = queryParams
        .replace(/(&|\?)query=.*?&/gi, "")
        .replace(/(&|\?)?category=.*?&/gi, "")
        .replace(/(&|\?)?filters=.*?&/gi, "")
        .replace(/(&|\?)tags=.*/gi, "");
    let queryMatch = query.match(/(&|\?)?manufacturers=.*/gi);
    if (!queryMatch)
        return [];
    let array: string[];
    try {
        array = decodeURI(queryMatch[0].replace(/(&|\?)?manufacturers=/gi, ""))?.split(",");
    } catch {
        array = [];
    }
    return array;
};

export const getFiltersString = (queryParams: string): string => {
    if (!queryParams?.includes("filters="))
        return "";

    let query = queryParams
        .replace(/(&|\?)query=.*?&/gi, "")
        .replace(/(&|\?)?category=.*?&/gi, "")
        .replace(/(&|\?)manufacturers=.*/gi, "")
        .replace(/(&|\?)tags=.*/gi, "");
    let queryMatch = query.match(/(&|\?)?filters=.*/gi);
    return queryMatch ?
        queryMatch[0].replace(/(&|\?)?filters=/gi, "") : "";
};

export const getCategoriesString = (queryParams: string): string => {
    if (!queryParams?.includes("category="))
        return "";

    let query = queryParams
        .replace(/(&|\?)query=.*?&/gi, "")
        .replace(/(&|\?)filters=.*/gi, "")
        .replace(/(&|\?)manufacturers=.*/gi, "")
        .replace(/(&|\?)tags=.*/gi, "");
    let queryMatch = query.match(/(&|\?)?category=.*/gi);
    return queryMatch ?
        queryMatch[0].replace(/(&|\?)?category=/gi, "") : "";
};

export const getTagsString = (queryParams: string): string[] => {
    if (!queryParams?.includes("tags="))
        return [];

    let query = queryParams
        .replace(/(&|\?)query=.*?&/gi, "")
        .replace(/(&|\?)category=.*/gi, "")
        .replace(/(&|\?)manufacturers=.*/gi, "")
        .replace(/(&|\?)filters=.*/gi, "");
    let queryMatch = query.match(/(&|\?)?tags=.*/gi);
    if (!queryMatch)
        return [];

    let array: string[];
    try {
        array = decodeURI(queryMatch[0].replace(/(&|\?)?tags=/gi, ""))?.split(",");
    } catch {
        array = [];
    }
    return array;
};

export const getAvailabilities = (filtersString: string): string[] => {
    const filtersArray = filtersString.split(";");
    let array = [];
    if (filtersArray.includes("available"))
        array.push("Available");
    if (filtersArray.includes("limited"))
        array.push("Limited");
    if (filtersArray.includes("unavailable"))
        array.push("Unavailable");
    if (filtersArray.includes("discontinued"))
        array.push("Discontinued");

    return array;
}