import { createState, useState } from '@hookstate/core';

interface CartToastData {
    maxItems: number;
    toasts: CartToastOptions[];
};

interface CartToastDataItem {
    id: string;
    title: string;
    price: string;
    quantity: number;
    imageUrl?: string;
}

interface CartToastOptions {
    /**
     * The toast ID. This is automatically generated by the plugin,
     * any values passed here will be overridden
     */
    id?: string;
    /**
     * The product data.
     */
    product: CartToastDataItem;
    /**
     * The toast state. This value is always overridden
     * when showing/hiding messages
     */
    active?: boolean;
    /**
     * Delay time in miliseconds before dismissing the message.
     * 
     * @defaultValue 8000
     */
    delayInMs?: number;
};

const animationDelay = 310;
const cartToastState = createState<CartToastData>({ toasts: [], maxItems: 5 });
const stateWrapper = (state: any) => ({
    getMaxItems: (): number => state?.value?.maxItems,
    getToasts: (): CartToastOptions[] => state?.value?.toasts || [],
    show: (options: CartToastOptions): string => {
        const toastId = "toast-" + new Date().getTime();
        if (options.id) {
            console.warn(
                "Trying to display a toast message with an ID set, " +
                `the value "${options.id}" will be ignored and ` + 
                `replaced with "${toastId}".`
            );
        }
        
        const toastRef = accessCartToast();
        const maxItems = toastRef.getMaxItems();
        
        state?.set((c: CartToastData) => {
            const delayInMs = options.delayInMs || 8000;
            c.toasts.unshift({
                delayInMs,
                id: toastId,
                active: true,
                product: options.product,
            });
            
            c.toasts.forEach((el, idx) => {
                if (idx >= maxItems && el.id)
                    setTimeout(() => accessCartToast().close(el.id!), 1);
            });

            setTimeout(() => accessCartToast().close(toastId), delayInMs);
            return c;
        });      

        return toastId;
    },
    close: (toastId: string) => {
        const el = document.getElementById(toastId);
        el?.classList.add("closing");

        setTimeout(() => {
            state?.set((c: CartToastData) => {
                const toastIndex = c.toasts
                    .findIndex(toast => toast.id === toastId);
                if (toastIndex > -1)
                    c.toasts?.splice(toastIndex, 1);

                return c;
            });            
        }, animationDelay * 2);
    }
});

export const accessCartToast = () => stateWrapper(cartToastState);
export const useCartToast = () => stateWrapper(useState(cartToastState));