import { OrderShipmentItem } from "./OrderShipmentItem"

export class OrderItem {
    public id = 0
    public sku = ''
    public name = ''
    public qtyOrdered = 0
    public price = 0
    public total = 0
    public itemStatus = ''
    public shipments: OrderShipmentItem[] = []

    constructor (data: any = {}) {
      this.id = data.id
      this.sku = data.sku
      this.name = data.name
      this.qtyOrdered = data.qtyOrdered
      this.price = data.price
      this.total = data.total
      this.itemStatus = data.itemStatus
      
      this.shipments = [];

      if (data.shipments) {
          for (const shipmentData of data.shipments) {
            this.shipments.push(new OrderShipmentItem(shipmentData));
          }
      }
    }
}
