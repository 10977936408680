import React from 'react';
import BusinessSettingsMenuItem from './item';
import UserStorage from '../../../../utils/userStorage';

interface PropTypes {
    onItemSelect(): void;
}

export default function BusinessSettingsMenu(props: PropTypes) {
    return (<>
        <BusinessSettingsMenuItem
            slug="shared-accounts"
            title="Manage Accounts"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
        {UserStorage.isCompany() && <>
            <BusinessSettingsMenuItem
                slug="shared-settings"
                title="Shipping and Payment Policies"
                setMobileMenuOpen={() => props.onItemSelect()}
            />
            <BusinessSettingsMenuItem
                slug="notifications"
                title="Order Notifications"
                setMobileMenuOpen={() => props.onItemSelect()}
            />
            <BusinessSettingsMenuItem
                slug="product-preferences"
                title="Product Preferences"
                setMobileMenuOpen={() => props.onItemSelect()}
            />
            <BusinessSettingsMenuItem
                slug="order-info"
                title="Business Order Info"
                setMobileMenuOpen={() => props.onItemSelect()}
            />
        </>}
    </>);
}