import React from 'react';
import './styles.scss';
import cn from 'classnames';

type TModalProps = {
  open: boolean;
  content: React.ReactNode;
  onClose: () => void;
}

export default function Modal({ open, content, onClose }: TModalProps) {
  return (
    <div className={cn({ 'overlay': open })}>
      <div className='content card-details'>
        <button className="close-btn" onClick={onClose}>
            X
        </button>
        {content}
      </div>
    </div>
  )
}
