import { createState, useState } from '@hookstate/core';

const sidebarState:any = createState({
    cart: {
        open: false
    },
    catcept: {
        open: false
    },
    filter: {
        open: false,
        actives: 0
    }
});
const stateWrapper = (state:any) => ({
    getStateFor: (sidebarName:string) => state?.value[sidebarName],
    setStateFor: (sidebarName:string, stateValue:any) => 
        state[sidebarName]?.set((s:any) => 
        { 
            return { ...s, ...stateValue }; 
        })
});

export const accessSidebar = () => stateWrapper(sidebarState);
export const useSidebar = () => stateWrapper(useState(sidebarState));