import React from 'react';
import { useCartToast } from '../../stores/cartToastStore';
import CartToastItem from './item';

import './styles.scss';

export default function CartToast() {
    const toast = useCartToast();
    const toasts = toast.getToasts();

    return (
        <div id="cart-toast">
            {toasts.map(toast => 
                <CartToastItem 
                    key={toast.id}
                    id={toast.id || ""}
                    title={toast.product.title}
                    price={toast.product.price}
                    productId={toast.product.id}
                    imageUrl={toast.product.imageUrl}
                    quantity={toast.product.quantity}
                />   
            )}
        </div>
    );
};