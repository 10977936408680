import { createState, useState } from '@hookstate/core';

interface StateTypes {
    /**
     * Programmatically cancel the dialog.
     */
    cancel(): void;
    /**
     * Programmatically confirm the dialog.
     */
    confirm(): void;
    /**
     * @returns  Current options set.
     */
    getOptions(): DialogOptions;
    /**
     * Displays a dialog with passed args configuration.
     * 
     * @param   options  Dialog options. See the {@link DialogOptions} 
     *                   interface for available configs.
     * 
     * @returns          A promise with the user choice as an argument.
     *                   Confirmation dialogs returns `true` if the user confirms 
     *                   the dialog and `false` if cancels it.
     */
    show(options: DialogOptions): Promise<boolean>;
};

interface DialogOptions {
    /**
     * The dialog title.
     */
    title?: string;
    /**
     * The dialog state. This value is always overridden
     * when showing/hiding dialogs
     */
    active?: boolean;
    /**
     * The dialog message.
     */
    message?: any;
    /**
     * The message displayed in the cancel button.
     * 
     * @defaultValue Cancel
     */
    cancelLabel?: string;
    /**
     * The message displayed in the confirm button.
     * 
     * @defaultValue OK
     */
    confirmLabel?: string;
    /**
     * The dialog type. `alert` displays only a confirmation button, use it
     * when you want to show a blocking message to the user.
     * `confirm` will require the user to confirm or cancel the dialog, use it
     * when you explicit want the user to take a choice.
     * 
     * @defaultValue alert
     */
    type?: "alert" | "confirm";
};

var resolvePromise: (result: boolean) => void;
const dialogState: any = createState({ active: false });
const stateWrapper: (state: any) => StateTypes = (state: any) => ({
    getOptions: (): DialogOptions => state?.value,
    show: (options: DialogOptions): Promise<boolean> => {
        state?.set((p: DialogOptions) => {
            p.active = true;
            p.title = options.title;
            p.type = options.type || "alert";
            p.message = options.message;
            p.confirmLabel = options.confirmLabel || "OK";
            p.cancelLabel = options.cancelLabel || "Cancel";

            return p;
        });

        return new Promise<boolean>((resolve) => {
            resolvePromise = resolve;
        });
    },
    cancel: () => {
        state?.set((p: DialogOptions) => {
            p.active = false;
            resolvePromise(false);
            return p;
        })
    },
    confirm: () => {
        state?.set((p: DialogOptions) => {
            p.active = false;
            resolvePromise(true);
            return p;
        });
    },
});

export const accessDialog = () => stateWrapper(dialogState);
export const useDialog = () => stateWrapper(useState(dialogState));