import React, { useState } from "react";
import settings from "../../../settings";
import { Product } from "../../../models";
import SubmitButton from "../../form/submit-button";
import UserStorage from "../../../utils/userStorage";
import { useFlash } from "../../../stores/flashStore";
import CartService from "../../../services/CartService";
import { useCartToast } from "../../../stores/cartToastStore";
import { ErrorMessage } from "../../../services/ErrorHandlingService";
import { useReactiveProducts } from "../../../stores/reactiveProductStore";

import notFoundImage from '../../../assets/images/no-image.jpg';
import './styles.scss';

interface PropTypes {
    item: Product;
    searchQuery?: string;
    actionButtonLabel?: string;
    onActionButtonClick?(): void;
    allowUnavailableSelection?: boolean;
    onSelect(hasVariants?: boolean): void;
}

export default function ProductItemAutocomplete(props: PropTypes) {
    const product = props.item;

    const flash = useFlash();
    const toast = useCartToast();
    const reactiveProductsState = useReactiveProducts();
    const reactiveProduct = reactiveProductsState.get(product.id);
    const hasVariants = product.variantIds && product.variantIds.length > 1;

    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = 
        useState(props.actionButtonLabel || "Add to cart");

    const addProductToCart = () => {
        if (product.availabilityId === "unavailable")
            return;

        setLoading(true);
        CartService.addItem(product)
            .then(() => {
                setButtonText("Added!");
                toast.show({ 
                    product: {
                        quantity: 1,
                        id: product.id,
                        title: product.title,
                        imageUrl: product.thumbnailUrl,
                        price: (product.hasSpecialPrice() 
                            ? product.getSpecialPrice()
                            : product.getPrice()) || "-"
                    }
                });
            })
            .catch((errors: ErrorMessage[]) => {
                if (errors.some(e => e.code === "PRODUCT_UNAVAILABLE")) {
                    flash.show({
                        type: "error",
                        message: "The current product is unavailable. Please refresh your page."
                    });
                    setButtonText("Product unavailable");
                } else {
                    setButtonText("Couldn't add the product!");
                }
            })
            .finally(() => {
                setLoading(false);
                setTimeout(() => setButtonText("Add to cart"), 1000);
            });
    };

    const getAvailabilityId = (): string => {
        if (reactiveProduct && reactiveProduct.updated) {
            return reactiveProduct.availability
                .replace(" ", "-").toLowerCase();
        } else {
            return product!.availabilityId;
        }
    };

    const getAvailability = (): string => {
        if (reactiveProduct && reactiveProduct.updated) {
            return reactiveProduct.availability;
        } else {
            return product!.availability;
        }
    };

    return (
        <div className="dropdown-item">
            <button
                type="button"
                className="link"
                onClick={() => props.onSelect(hasVariants)}
            >
                <div className="thumb">
                    <img
                        alt={product.title}
                        onError={(e: React.SyntheticEvent<HTMLImageElement>) =>
                            e.currentTarget.src = notFoundImage
                        }
                        src={product.thumbnailUrl}
                    />
                </div>
                <div className="info">
                    <div>
                        {product.title}
                        {product.variantIds && product.variantIds.length > 1 &&
                            <>
                                {" "}(+{product.variantIds.length - 1}{" "}
                                item{product.variantIds.length > 2 && "s"})
                            </>
                        }
                    </div>
                    <div>
                        {product.hasSpecialPrice() ?
                            <>
                                {product.getSpecialPrice()}{" "}
                                <span className="old-value">
                                    {product.getPrice()}
                                </span>
                            </>
                            :
                            product.getPrice()
                        }
                        {
                            getAvailabilityId() === "unavailable" &&
                            <span className="unavailable">
                                {" • " + getAvailability()}
                            </span>
                        }
                    </div>
                </div>
            </button>
            {
            (getAvailabilityId() !== "unavailable" || 
                props.allowUnavailableSelection
            ) &&
            (props.onActionButtonClick || 
                (!props.onActionButtonClick && !UserStorage.isCompanyBuyer())
            ) &&
                <SubmitButton
                    type="button"
                    loading={loading}
                    label={buttonText}
                    disabled={hasVariants}
                    onClick={props.onActionButtonClick || addProductToCart}
                    title={hasVariants ? "Product has more than one variant" : ""}
                />
            }
        </div>
    );
}