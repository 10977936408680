import React from 'react';
import ManageOrdersMenuItem from './item';

interface PropTypes {
    onItemSelect(): void;
}

export default function ManageOrdersMenu(props: PropTypes) {
    return (<>
        <ManageOrdersMenuItem
            slug="open"
            title="Open Orders"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
        <ManageOrdersMenuItem
            slug="canceled"
            title="Canceled Orders"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
        <ManageOrdersMenuItem
            slug="backorders"
            title="Backorders"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
        <ManageOrdersMenuItem
            slug="completed"
            title="Completed Orders"
            setMobileMenuOpen={() => props.onItemSelect()}
        />
    </>);
}