export enum CmsLinkType {
    BLOG = "BLOG",
    PAGE = "PAGE",
    LINK = "LINK",
    CATCEPT = "CATCEPT",
}

export default class CmsLink {
    public id = "";
    public url = "";
    public title = "";
    public parentTitle = "";
    public userGroups: string[] = [];
    public type: CmsLinkType = CmsLinkType.LINK;

    constructor(data: any = {}) {
        this.id = data.id;
        this.url = data.url;
        this.title = data.title;
        this.userGroups = data.userGroups;
        this.parentTitle = data.parentTitle;
        this.type = data.type || CmsLinkType.LINK;
    }
}