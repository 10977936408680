import React from 'react';
import Flash from '../../components/flash';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Dialog from '../../components/dialog';
import CartToast from '../../components/cart-toast';
import CartSideBar from '../../components/side-bar/cart';
import CatceptSideBar from '../../components/side-bar/catcept';

import './styles.scss';

interface TypeProps {
    children?: any;
    hideFooter?: boolean;
    hideSearchBar?: boolean;
    hideCartSideBar?: boolean;
}

export default function MainTemplate(props: TypeProps) {
    return (<>
        <Header 
            hideSearchBar={props.hideSearchBar}
            hideCartButton={props.hideCartSideBar}
        />
        <div className="container">
            <CatceptSideBar />

            <div className="body">
                {props.children}

                {!props.hideFooter &&
                    <Footer />
                }
            </div>

            {!props.hideCartSideBar &&
                <CartSideBar />
            }
        </div>
        <CartToast />
        <Dialog />
        <Flash />
    </>);
}