
export class PaymentMethods {
  public code: string = '';
  public title: string = '';

  constructor(data: any = {}) {
      this.code = data.code;
      this.title = data.title;
  }
}
