import React from 'react';
import settings from '../../settings';
import { Link, useHistory } from 'react-router-dom';
import SubmitButton from '../form/submit-button';
import { useCartToast } from '../../stores/cartToastStore';

import notFoundImage from '../../assets/images/no-image.jpg';
import './styles.scss';

interface PropTypes {
    id: string;
    title?: string;
    price?: string;
    productId: string;
    quantity?: number;
    imageUrl?: string;
}

export default function CartToastItem(props: PropTypes) {
    const history = useHistory();
    const toast = useCartToast();

    const onClose = () => toast.close(props.id);
    const getImageUrl = () => `${settings.env.REACT_APP_IMAGE_BASE_URI}/${props.imageUrl}`;

    return (
        <div id={props.id} className="toast">
            <div className="header">Just Added to Cart</div>
            <button className="close-btn" onClick={onClose}>X</button>
            <div className="content">
                <div className="main-info">
                    <Link className="image-container" to={`/${props.productId}`}>
                        <img 
                            alt="product" 
                            src={props.imageUrl ? getImageUrl() : notFoundImage} 
                        />
                    </Link>
                    <div className="title">
                        ({props.quantity || "1"}x) {props.title}
                    </div>
                </div>
                <div className="footer">
                    <div className="price">{props.price}</div>
                    <SubmitButton 
                        label="View Cart"
                        customClass="btn"
                        onClick={() => history.push("/cart")}
                    />
                </div>
            </div>
        </div>
    );
};