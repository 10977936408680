export class OrderDocument {
  public id?: number = 0;
  public file: string = '';
  public description: string = ''; // Changed from number to string
  public email?: string = ''; // Added email field
  public createdAt?: Date = new Date();

  constructor (data: any = {}) {
    this.id = data.id;
    this.file = data.file;
    this.description = data.description;
    this.email = data.email;
    this.createdAt = data.createdAt;
  }
}