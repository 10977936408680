
export class BuyerGroupInput {
    public id: number = 0;
    public name: string = '';
    public priceListId: string = '';
    public paymentMethods: string[] = [];

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.priceListId = data.priceListId;
        this.paymentMethods = data.paymentMethods as string[];
    }
}
