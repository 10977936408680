import React from 'react';
import { useDialog } from '../../stores/dialogStore';

import './styles.scss';

export default function Dialog() {
    const dialog = useDialog();
    const options = dialog.getOptions();

    const onConfirm = () => dialog.confirm();
    const onCancel = () => dialog.cancel();

    if (!options.active)
        return null;

    return (
        <div className="dialog-overlay">
            <div className={"dialog-content " + options.type}>
                <div className="header">{options.title}</div>
                <div className="body">{options.message}</div>
                <div className="footer">
                    <button className="primary" onClick={onConfirm}>
                        {options.confirmLabel}
                    </button>
                    {options.type === "confirm" &&
                        <button className="secondary" onClick={onCancel}>
                            {options.cancelLabel}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};