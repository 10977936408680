import UploadFile from "./UploadFile";
import CmsProduct from "./CmsProduct";

export enum CmsMixedItemType {
    IMAGE = "IMAGE",
    PRODUCT = "PRODUCT",
}

export default class CmsMixedItem {
    public item_link = "";
    public item_file:File|undefined;
    public product = new CmsProduct();
    public item_image = new UploadFile();
    public type = CmsMixedItemType.PRODUCT;
    
    constructor(data: any = {}) {
        if (!data)
            return;

        this.item_link = data.item_link;
        this.item_file = data.item_file;
        this.product = new CmsProduct(data.product);
        this.item_image = new UploadFile(data.item_image);
        this.type = data.type ? 
            CmsMixedItemType[data.type as keyof typeof CmsMixedItemType] :
            CmsMixedItemType.PRODUCT;
    }
}