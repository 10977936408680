export default class CmsProduct {
    public title = "";
    public image_url = "";
    public product_id = "";
    
    constructor(data: any = {}) {
        if (!data)
            return;

        this.title = data.title;
        this.image_url = data.image_url;
        this.product_id = data.product_id;
    }
}